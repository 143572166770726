import { useDistrictQuery } from "hooks/useMasterData";
import { useState } from "react";
import AsyncSelectForm from ".";

const SelectDistrictOptions = ({ ...props }) => {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [search, setSearch] = useState("");

  const { data: districtData, isFetching } = useDistrictQuery([
    "district-options",
    { page, limit, search, city_id: props.cityId, ...props.filter },
  ], {enabled: !!props.cityId});

  return (
    <AsyncSelectForm
      {...props}
      data={districtData?.data?.result?.data}
      totalPages={districtData?.data?.result?.meta?.total}
      getOptionLabel={(opt) => opt?.dis_name}
      getOptionValue={(opt) => opt?.dis_id}
      isFetching={isFetching}
      onChangePage={setPage}
      onChangeSearch={setSearch}
      menuPortalTarget={document.body}
    />
  );
};

export default SelectDistrictOptions;
