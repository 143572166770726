import {
  Breadcrumbs,
  SearchInput,
  Table,
  TitleText
} from "components";
import SelectProgramOptions from "components/HookForm/AsyncSelectForm/select-program";
import { SideModal } from "components/Modal";
import { useUploadFile } from "hooks/externalService";
import { useProgramAssignmentPatch, useProgramAssignmentQuery } from "hooks/useProgramAssignment";
import { debounce } from "lodash";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { Fragment, useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { HiOutlineEye } from "react-icons/hi";
import Detail from "./Detail";
import Form from "./Form";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";

const DEFAULT_VALUE = {
  assignment_number: "",
  name: "",
  nik: "",
  phone_number: "",
  start_date: null,
  program: []
};

const ProgramAssignment = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");
  const [keyword, setKeyword] = useState("");

  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);

  const schema = yup.object({
    assignment_number: yup.string().required("Tidak boleh kosong"),
    program: yup.array().min(1, "Pilih minimal satu").required("Tidak boleh kosong"),
    start_date: yup.date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .required("Tidak boleh kosong"),
    document: yup
      .mixed()
      .test("required", "Tidak boleh kosong", function (value) {
        return !!value;
      })
      .test({
        message: "File tidak boleh lebih dari 10MB",
        test: (value) => {
          return value?.[0]?.size <= 10 * 1024 * 1024; // 10MB in bytes
        }
      })
      .test({
        message: "Format file hanya boleh PDF",
        test: (value) => {
          const supportedFormats = ["pdf"];
          return supportedFormats.includes(
            value?.[0]?.name.split(".").pop().toLowerCase()
          );
        }
      })
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUE,
  });

  const { watch, reset, handleSubmit } = methods; 

  const { data, isLoading, refetch } = useProgramAssignmentQuery([
    "program-assignment-list",
    {
      page,
      limit,
      search: keyword,
      sortBy: sortBy ? `${sortBy}:${sortType}` : "",
      programs: watch("program-options") ? watch("program-options")?.program_id : ""
    },
  ]);

  const uploadFile = useUploadFile();
  const patch = useProgramAssignmentPatch()


  const links = [
    {
      label: "Penugasan",
    },
  ];

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "w-fit",
      render: (value, data, index) => {
        const no =
          parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[140px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "nik",
      title: "NIK",
      dataIndex: "nik",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (column, methods) => {
        setSortBy(column);
        setSortType(methods);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "name",
      title: "Nama PIC",
      dataIndex: "name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "programs",
      title: "Program",
      dataIndex: "programs",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "total_approved_programs",
      title: "Total Disetujui",
      dataIndex: "total_approved_programs",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "total_rejected_programs",
      title: "Total Ditolak",
      dataIndex: "total_rejected_programs",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Action",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      fixed: "right",
      render: (value, data, index) => (
        <div className="flex items-center w-fit gap-4">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => {
              onOpenDetail(data);
            }}
          />

          <BiPencil
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => onOpenEdit(data)}
          />
        </div>
      ),
    },
  ];

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeRowsPerPage = (limit) => {
    setLimit(limit);
  };

  const onOpenEdit = (data) => {
    setSelectedDetail(data?.user_id);
    setIsOpenEdit(true);

    reset({
      name: data.name,
      nik: data.nik,
      program: [] 
    });
  };

  const onOpenDetail = (data) => {
    setSelectedDetail(data);
    setIsOpenDetail(true);
  };

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const onSubmit = async (data) => {
    const { assignment_number, start_date, program } = data;

    const payload = {
      start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : null,
      program_id: program.length > 0 ? program.map((program) => program.program_id) : "",
      assignment_number,
    }

    // Form Data for uploading File
    const formDataAssignment = new FormData();

    formDataAssignment.append("file", data?.document[0]);
    const uploadAttachment = uploadFile.mutateAsync(formDataAssignment);
    const uploadData = await Promise.all([uploadAttachment]);

    try {
      const params = {
        id: selectedDetail,
        payload: {
          ...payload,
          supporting_document: uploadData[0].data.url,
        },
      };

      patch.mutate(params, {
        onSuccess: (data, variables) => {
          setIsOpenEdit(false);
          setSelectedDetail(null);
          reset(DEFAULT_VALUE);
          refetch();
          enqueueSnackbar({
            message: `Berhasil mengubah data`,
            variant: "success",
          });
        },
      });
    } catch (error) {
      enqueueSnackbar({
        message: `Gagal Upload`,
        variant: "error",
      });
    }
  }

  const renderModal = () => {
    return (
      <Fragment>
        {/* Modal Pindah Tugas */}
        {isOpenEdit && (
          <SideModal
            title="Pindah Tugas PIC"
            open={isOpenEdit}
            onClose={() => {
              setIsOpenEdit(false);
              setSelectedDetail(null);
              reset();
            }}
            onSubmit={handleSubmit(onSubmit)}
            width="w-1/3"
            position="center-right"
            modalClassName="w-1/3 h-screen rounded-none"
            btnLabelSubmit="Kirim"
            btnLabelCancel="Batal"
          >
            <FormProvider {...methods}>
              <Form />
            </FormProvider>
          </SideModal>
        )}

        {/* Modal Detail Petugas */}
        <SideModal
          title="Detail Petugas"
          open={isOpenDetail}
          onClose={() => {
            setIsOpenDetail(false);
            setSelectedDetail(null);
          }}
          onSubmit={(data) => {
            setIsOpenDetail(false);
            onOpenEdit(selectedDetail);
          }}
          width="w-1/2"
          position="center-right"
          modalClassName="w-1/2 h-screen rounded-none"
          btnLabelSubmit="Pindah Tugas"
          btnLabelCancel="Tutup"
        >
          <Detail selectedUser={selectedDetail} />
        </SideModal>
      </Fragment>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      {renderModal()}

      <Breadcrumbs items={links} />

      <FormProvider {...methods}>
        <div className="flex items-center justify-between">
          <div className="flex items-center my-3 justify-between flex-1">
            <TitleText className="flex-1">Penugasan Program</TitleText>
          </div>
          <div className="flex items-center space-x-4 flex-1">
            <div className="flex-1">
              <SearchInput placeholder="Pencarian" onChange={onSearch} />
            </div>
            <div className="flex-1">
              <SelectProgramOptions name="program-options" placeholder="Pilih Program" isClearable />
            </div>
          </div>
        </div>
      </FormProvider>

      {/* Table */}
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={data?.result}
              isLoading={isLoading}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={{
                page: data?.paginator?.page ?? 1,
                limit: data?.paginator?.limit ?? 10,
                itemCount: data?.paginator?.itemCount ?? 0,
                previous_pages: data?.paginator?.hasPrevPage,
                next_pages: data?.paginator?.hasNextPage,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramAssignment;
