import { useQuery } from "react-query";
import { getDeptorDetail, getDeptorList, getDeptorMonev, getDeptorMonitoringPlan } from "services/deptor";

export const useDeptorQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      const response = await getDeptorList({ ...params }, signal);
      return response.data
    },
    ...options,
  });
};

export const useDeptorDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: async ({ signal, queryKey }) => {
      const id = queryKey[1];
      const response = await getDeptorDetail(id, signal);
      return response.data
    },
    ...options,
  });
};

export const useDeptorMonitoringPlan = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: async ({ signal, queryKey }) => {
      const id = queryKey[1];
      const response = await getDeptorMonitoringPlan(id, signal);
      return response.data
    },
    ...options,
  });
};

export const useDeptorMonitoringEvaluasi = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: async ({ signal, queryKey }) => {
      const id = queryKey[1];
      const response = await getDeptorMonev(id, signal);
      return response.data
    },
    ...options,
  });
};