import { Button, CollapsingCard, Label } from "components";
import { FilePickerForm, InputForm } from "components/HookForm";
import { useFieldArray } from "react-hook-form";
import { BiTrash } from "react-icons/bi";

const AdditionalInformation = ({ control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "documents",
  });

  const additionalInformation = [
    "Penyiapan MoU dengan Disperindag saat ini belum bisa dilakukan, yang dapat direalisasikan baru pada tahap pembinaan.",
    "Penyiapan MoU dengan Disperindag saat ini belum bisa dilakukan, yang dapat direalisasikan baru pada tahap pembinaan.",
    "Kegiatan dilaksanakan langsung oleh ketua tim Pengmas, tidak melalui lembaga Kampus.",
    "Melihat potensi keberlanjutan dari kegiatan ini, pihak lembaga Kampus menginisiasi untuk melanjutkan kegiatan binaan pengabdian masyarakat dengan menugaskan beberapa mahasiswa untuk melakukan binaan ke masyarakat.",
    "Program ini tentunya untuk menggali potensi bisnis yang menyelaraskan juga dengan program dana desa.",
    "Pada tahun 2024 ini, UNRI telah merancang kegiatan untuk desa binaan yakni 1 KJF untuk 1 desa binaan. Direncanakan ada 7 desa yang jenis kegiatannya bervariasi.",
    "Produk kerajinan Piring dari lidi sawit digunakan untuk keperluan upacara keagamaan/hajatan warga. Tim telah berinisiasi untuk bekerjasama dengan Bumdes untuk membuat produk souvenir bagi wisatawan. Walaupun di rupat keadaan alamnya tidak sebagus pantai yang berada di Jawa, Bali dan Lombok.",
    "Membantu pemasaran untuk produk yang dihasilkan dengan harapan menjadi kekuatan ekonomi baru yang dapat membantu kepala keluarga telah dilakukan dengan menyewakan produk piring tersebut untuk keperluan acara hajatan.",
    "Keberhasilan penjualan ada di Desa Darul Aman. Mahasisa UNRI ditugaskan untuk KKN guna mengevaluasi hal tersebut. Pada fase awal produk hanya untuk kegiatan acara hajatan keagamaan, lalu setelah 6 bulan produk dititpkan ke warung klontong untuk dijual.",
    "Produk yang dibuat juga dipasarkan dengan cara disewakan.",
    "Harapannya Disperindag dapat menampung produk suku Akit untuk disalurkan (titip jual) kepada mitra pedagan guna meningkatkan penjualan dan pendapatan masyarakat suku Akit.",
    "Lidi sawit juga sebenarnya dapat dijual secara langsung tanpa dibuat kerajinan, mengingat di kota Dumai sudah ada pengepul/pedagang yang siap untuk membelinya dengan ketentuan dapat memenuhi permintaan minimum order (biasanya 1 ton per order). Berdasarkan info dari salah satu pedagang lidi, harga lidi apabila ukuran seragam dihargai Rp.4.500,- per kilo dan apabila campur dihargai Rp.3.900,- per kilo.",
    // tantangan
    "Status Suku Akit saat ini desanya belum tercatat sebagai desa adat secara pemerintahan. Hal ini berakibat tidak terdaftarnya dalam list Masyarakat Hukum Adat (MHA). Suku asli Akit merupakan orang yang terbiasa hidup di atas perahu (sampan), dan kini mendiami pulau yang masuk dalam Kecamatan Rupat Kabupaten Bengkalis",
    "Kondisi geografis/ lokasi, akses, dan transportasi serta penyebrangan yang masih belum memenuhi kebutuhan yang ideal untuk berbisnis skala menengah.",
    "Pemkab. Bengkalis masih berbenah untuk Kecamatan Rupat. Wawasan masyarakat untuk mempromosikan produk dinilai masih minim karena kebanyakan tidak punya rekening bank dan juga tidak tersedianya fasilitas mesin ATM / agen bank di Kecamatan Rupat.",
    "Kecamatan Rupat masih berbenah, dahulu kawasan ini merupakan perkebunan karet, saat ini adalah banyaknya ditanami kelapa sawit karena lahannya gambut. Di pulau Rupat tidak ada pabrik pengolahan buah tandan kelapa sawit, semua TBS diolah ke kota Dumai.",
    "Mesin peraut lidi sawit membutuhkan daya listrik yang cukup besar, apabila ingin menggunakan, maka harus bergiliran dengan listrik rumah.",
    "Diperlukan simulasi perhitungan bisnis (business plan) perihal biaya operasional listriknya, BBM (jika dengan genset), maintenance masin dan profit yang diperoleh. Hal ini penting untuk diketahui agar keberlanjutan usaha dapat terus berjalan.",
  ];

  return (
    <CollapsingCard title="Informasi Tambahan Lainnya">
      <div className="flex flex-col gap-6">
        <div className="space-y-4 p-6 border border-primary-600 rounded">
          <div className="flex flex-col gap-1">
            <span className="text-lg font-semibold">Informasi Tambahan</span>
            <hr />
          </div>

          <div className="flex flex-col gap-1">
            <span className="text-sm">Beberapa Informasi:</span>
            <ul className="list-disc space-y-1">
              {additionalInformation.map((info, index) => (
                <li key={index} className="text-xs ml-4">
                  {info}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="space-y-4 p-6 border border-primary-600 rounded">
          <span className="text-lg font-semibold">Realisasi Keuangan</span>

          <div className="flex items-center gap-64">
            <div className="flex flex-col gap-1">
              <span className="text-sm font-bold">Rencana</span>
              <span>Rp.44.800.000,-</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-sm font-bold">Realisasi</span>
              <span>Rp.44.800.000,-</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-sm font-bold">Persentase</span>
              <span>100%</span>
            </div>
          </div>
        </div>

        <div className="space-y-4 p-6 border border-primary-600 rounded">
          <div className="flex flex-col gap-1">
            <span className="text-lg font-semibold">Dokumentasi</span>
            <hr />
          </div>

          {fields.map((item, index) => (
            <div
              key={item.id}
              className="flex flex-col gap-4 p-4 border border-primary-600 rounded"
            >
              <div className="flex justify-end">
                <BiTrash
                  size={20}
                  fill="#ef4444"
                  className="cursor-pointer hover:opacity-85"
                  onClick={() => remove(index)}
                />
              </div>

              <div className="flex flex-col gap-1">
                <Label>Keterangan Dokumentasi</Label>
                <InputForm
                  name={`documents[${index}].document_desc`}
                  placeholder="Keterangan Dokumentasi"
                  className="w-full"
                  control={control}
                />
              </div>

              <div className="flex flex-col gap-1">
                <FilePickerForm
                  name={`documents[${index}].file`}
                  label="Upload Dokumentasi"
                  required
                  control={control}
                />
              </div>
            </div>
          ))}

          <Button
            className="bg-primary-600 text-white hover:bg-primary-700"
            onClick={() => append({ document_desc: "", file: null })}
          >
            Tambah Dokumentasi
          </Button>
        </div>
      </div>
    </CollapsingCard>
  );
};

export default AdditionalInformation;
