import {
  Breadcrumbs,
  Button,
  CollapsingCard,
  Label,
  TitleText,
} from "components";
import { CheckboxForm, CustomSelectForm, InputForm } from "components/HookForm";
import { FormModal } from "components/Modal";
import { useKegiatanProgressDetail } from "hooks/useKegiatanProgress";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router";
import { ACTIVITY_REPORT } from "services";
import DocIcon from "../../../../assets/doc-green.svg";

const ProgramPhysicalAndFinancialDetail = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const params = useParams();

  const { data } = useKegiatanProgressDetail(params?.id);

  const mutationUpdateStatus = useMutation((payload) => {
    ACTIVITY_REPORT.putUpdateStatusLaporan(payload);
  });

  const [approveModal, setApproveModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);

  const links = [
    {
      label: "Kegiatan",
      path: "#",
    },
    {
      label: "Laporan Kegiatan Fisik dan Keuangan",
      path: "/program-physical-and-financial",
    },
    {
      label: "Detail",
    },
  ];

  const onSubmitApproved = () => {
    const payload = {
      id: params.id,
      payload: {
        status: 1,
      },
    };
    mutationUpdateStatus.mutate(payload, {
      onSuccess: () => {
        setApproveModal(false);

        enqueueSnackbar({
          message: `Laporan telah disetujui`,
          variant: "success",
        });
      },
    });
  };

  const onSubmitDecline = () => {
    const payload = {
      id: params.id,
      payload: {
        status: 2,
      },
    };
    mutationUpdateStatus.mutate(payload, {
      onSuccess: () => {
        setDeclineModal(false);

        enqueueSnackbar({
          message: `Berhasil tolak laporan. Laporan yang ditolak akan masuk list investigasi`,
          variant: "success",
        });
      },
    });
  };

  const renderModal = () => {
    return (
      <>
        <FormModal
          open={approveModal}
          onClose={setApproveModal}
          onSubmit={onSubmitApproved}
          width="w-1/3"
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <p className="text-lg font-bold">Setujui Laporan?</p>
              <p className="text-sm text-[#475467]">
                Pastikan data sudah sesuai
              </p>
            </div>
            <CheckboxForm label="Setujui Bersyarat" name="approve" />

            <div className="flex flex-col gap-1">
              <Label>Alasan Disetujui</Label>
              <InputForm
                type="textarea"
                className="w-full"
                name="reason-approved"
                placeholder="Masukkan alasan disetujui"
              />
            </div>
          </div>
        </FormModal>
        <FormModal
          open={declineModal}
          onClose={setDeclineModal}
          onSubmit={onSubmitDecline}
          width="w-1/3"
          btnLabelSubmit="Tolak"
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <p className="text-lg font-bold">Tolak Laporan?</p>
              <p className="text-sm text-[#475467]">
                Pastikan data sudah sesuai
              </p>
            </div>

            <div className="flex flex-col gap-1">
              <Label>Kategori Penolakan</Label>
              <CustomSelectForm className="w-full" name="cateogry-decline" />
            </div>

            <div className="flex flex-col gap-1">
              <Label>Alasan Ditolak</Label>
              <InputForm
                type="textarea"
                className="w-full"
                name="reason-decline"
                placeholder="Masukkan alasan ditolak"
              />
            </div>
          </div>
        </FormModal>
      </>
    );
  };

  function convertBytes(bytes) {
    const KB = 1024;
    const MB = KB * 1024;

    let convertedValue;
    let unit;

    if (bytes >= MB) {
      convertedValue = bytes / MB;
      unit = "MB";
    } else {
      convertedValue = bytes / KB;
      unit = "KB";
    }

    return `${convertedValue.toFixed(2)} ${unit}`;
  }

  const RenderCardDocument = ({ doc }) => {
    return (
      <div className="border-gray-200 px-4 py-2 rounded-md border-2">
        <div className="flex items-start gap-4">
          <img src={DocIcon} alt="doc-icon" />
          <div classsName="text-sm font-normal">
            <p className="text-sm font-normal">Dokumen Bukti</p>
            <p>{convertBytes(doc.fileSize || 0)}</p>
            <p className="color-[##00BD52]">Lihat Dokumen</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-6 mb-16">
        {renderModal()}
        <Breadcrumbs items={links} />
        <div className="flex items-center my-3 justify-between">
          <TitleText className="flex-1">Laporan Kegiatan</TitleText>
          {(!data?.result?.status === 1 || !data?.result?.status === 2) && (
            <div className="space-x-5">
              <Button
                onClick={() => setDeclineModal(true)}
                className="w-32 border-gray-200 shadow-lg bg-white text-red-500 hover:bg-white hover:border-white hover:text-red-700"
              >
                Tolak
              </Button>
              <Button
                onClick={() => setApproveModal(true)}
                className="w-32 shadow-lg bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700 text-white"
              >
                Disetujui
              </Button>
            </div>
          )}
        </div>

        <CollapsingCard title="Pelaksanaan Kegiatan">
          <div className="grid grid-cols-1 gap-5">
            <div className="flex w-full flex-col gap-4">
              <p className="font-bold">Kegiatan Utama</p>
              <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">
                {data?.result?.activity_name}
              </p>
            </div>
            <div className="flex w-full flex-col gap-4">
              <p className="font-bold">Output Proyek</p>
              <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">
                {data?.result?.output_name}
              </p>
            </div>
            <div className="flex w-full flex-col gap-4">
              <p className="font-bold">Tanggal Kegiatan</p>
              <div className="flex gap-4 ">
                <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">
                  {moment(data?.result?.activity_date_start).format(
                    "DD/MM/YYYY"
                  )}
                </p>
                <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">
                  {moment(data?.result?.activity_date_end).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>

            <div className="flex w-full flex-col gap-5">
              <p className="font-bold">Tujuan Kegiatan</p>
              <p className="">{"-"}</p>
            </div>
            <div className="flex w-full flex-col gap-4">
              <p className="font-bold text-lg text-[#01A24A]">Keterlibatan</p>
              <div className="flex w-full gap-5">
                <div className="flex flex-col w-full">
                  <p className="font-bold">Perempuan</p>
                  <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">
                    {data?.result?.total_woman}
                  </p>
                </div>
                <div className="flex flex-col w-full">
                  <p className="font-bold">Laki-laki</p>
                  <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">
                    {data?.result?.total_man}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Hasil Kegiatan">
          <div className="grid grid-cols-1 space-y-10 bg-[#F7FFFA] p-6">
            <div className="flex items-center">
              <div className="flex w-full flex-col gap-4">
                <p className="font-bold">Target Hasil</p>
                <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">{"-"}</p>
              </div>
              <div className="flex w-full flex-col gap-4">
                <p className="font-bold">Jumlah</p>
                <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">{"-"}</p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex w-full flex-col gap-4">
                <p className="font-bold">Target Hasil</p>
                <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">{"-"}</p>
              </div>
              <div className="flex w-full flex-col gap-4">
                <p className="font-bold">Jumlah</p>
                <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">{"-"}</p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex w-full flex-col gap-4">
                <p className="font-bold">Target Hasil</p>
                <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">{"-"}</p>
              </div>
              <div className="flex w-full flex-col gap-4">
                <p className="font-bold">Jumlah</p>
                <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">{"-"}</p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 space-y-5 mt-6">
            <div className="flex w-full flex-col gap-4">
              <p className="font-bold">Hasil Kegiatan</p>
              <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">{"-"}</p>
            </div>
          </div>
          <div className="grid grid-cols-1 space-y-5 mt-6">
            <div className="flex w-full flex-col gap-4">
              <p className="font-bold">Dokumen Bukti Pelaksanaan kegiatan</p>
              {data?.result?.document.length > 0 ? (
                <div className="grid grid-cols-2">
                  {data?.result?.document?.map((doc) => {
                    return <RenderCardDocument doc={doc} />;
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Safeguard yang Diimplementasi">
          <div role="tablist" className="tabs tabs-lifted">
            {data?.result?.safeguard.map((tab, key) => {
              return (
                <>
                  <input
                    type="radio"
                    name={`SS_tab`}
                    role="tab"
                    className="tab"
                    aria-label={`SS ${key}`}
                    defaultChecked={key === 0}
                  />

                  <div
                    role="tabpanel"
                    className="tab-content bg-base-100 border-base-300 rounded-box p-6"
                  >
                    <p className="border-gray-200 px-4 py-2 rounded-md border-2 text-sm mb-5">
                      {tab.principle_name}
                    </p>

                    {tab.activity_list.length > 0
                      ? tab.activity_list.map((act, key) => {
                          return (
                            <CollapsingCard
                              title={`Kegiatan ${key + 1}`}
                              headerClassname="bg-green-100 text-green-600"
                            >
                              <div className="grid grid-cols-1 gap-5">
                                <div className="flex w-full flex-col gap-4">
                                  <p className="font-bold">Kegiatan Utama</p>
                                  <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">
                                    {act.activity || "Kegiatan"}
                                  </p>
                                </div>
                                <div className="flex w-full flex-col gap-4">
                                  <p className="font-bold">Deskripsi</p>
                                  <p className="bg-gray-200 p-4 mt-2 rounded-md w-full">
                                    {act.description || "Kegiatan"}
                                  </p>
                                </div>
                                <div className="flex w-full flex-col gap-4">
                                  <p className="font-bold">Dokumen Pendukung</p>
                                  {act.document?.length > 0 &&
                                    act.document.map((doc) => {
                                      return <RenderCardDocument doc={doc} />;
                                    })}
                                </div>
                              </div>
                            </CollapsingCard>
                          );
                        })
                      : null}
                  </div>
                </>
              );
            })}
          </div>
        </CollapsingCard>
      </div>
    </FormProvider>
  );
};

export default ProgramPhysicalAndFinancialDetail;
