import { CollapsingCard, Label } from "components";
import { InputForm } from "components/HookForm";
import React from "react";

const GeneralInformation = () => {
  return (
    <CollapsingCard title="Informasi Umum">
      <div className="p-6 border border-primary-600 rounded">
        <div className="grid grid-cols-2 gap-6">
          <div className="flex flex-col gap-1">
            <Label>Tanggal Kegiatan</Label>
            <InputForm name="activity_date" type="date" className="w-full" />
          </div>
          <div className="flex flex-col gap-1">
            <Label>Tanggal Laporan</Label>
            <InputForm name="report_date" type="date" className="w-full" />
          </div>

          <div className="flex flex-col gap-1">
            <Label>Pelaksana Monitoring</Label>
            <InputForm
              name="monitoring_implementer"
              className="w-full"
              placeholder="Pelaksana Monitoring"
            />
          </div>
          <div className="flex flex-col gap-1">
            <Label>Lokasi</Label>
            <InputForm
              name="location"
              className="w-full"
              placeholder="Lokasi"
            />
          </div>

          <div className="flex flex-col gap-1">
            <Label>Tujuan Kegiatan</Label>
            <InputForm
              name="activity_purpose"
              className="w-full"
              placeholder="Tujuan Kegiatan"
            />
          </div>
          <div className="flex flex-col gap-1">
            <Label>Penerima Manfaat/Lembaga Perantara</Label>
            <InputForm
              name="receiver"
              className="w-full"
              placeholder="Penerima Manfaat/Lembaga Perantara"
            />
          </div>

          <div className="flex flex-col gap-1">
            <Label>PIC Lembaga Perantara</Label>
            <InputForm
              name="intermediary_agency"
              className="w-full"
              placeholder="PIC Lembaga Perantara"
            />
          </div>
          <div className="flex flex-col gap-1">
            <Label>Judul Program/Kegiatan</Label>
            <InputForm
              name="activity_title"
              className="w-full"
              placeholder="Judul Program/Kegiatan"
            />
          </div>
        </div>
      </div>
    </CollapsingCard>
  );
};

export default GeneralInformation;
