import { Breadcrumbs, Button, TitleText } from "components";
import { FormProvider, useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import ActivityProgram from "./activity-program-section";
import AdditionalInformation from "./additional-information-section";
import ExcecutiveSummary from "./excecutive-summary-section";
import GeneralInformation from "./general-information-section";
import Recomendation from "./recomendation-section";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const DEFAULT_VALUE = {
  // ===== General Information =====
  activity_date: null,
  report_date: null,
  monitoring_implementer: "",
  location: "",
  activity_purpose: "",
  receiver: "",
  intermediary_agency: "",
  activity_title: "",
  // ===== Executive Summary =====
  brief_description: "",
  // ===== Recomendataion =====
  general_assesment: "",
  follow_up_from_bpdlh: "",
  follow_up_from_other: "",
  completion_time_bpdlh: null,
  completion_time_other: null,
  documents: [
    {
      document_desc: "",
      file: null,
    },
  ],
};

const schema = yup.object({
  // ===== General Information =====
  activity_date: yup.date().required("Tidak boleh kosong"),
  report_date: yup.date().required("Tidak boleh kosong"),
  monitoring_implementer: yup.string().required("Tidak boleh kosong"),
  location: yup.string().required("Tidak boleh kosong"),
  activity_purpose: yup.string().required("Tidak boleh kosong"),
  receiver: yup.string().required("Tidak boleh kosong"),
  intermediary_agency: yup.string().required("Tidak boleh kosong"),
  activity_title: yup.string().required("Tidak boleh kosong"),
  // ===== Executive Summary =====
  brief_description: yup.string().required("Tidak boleh kosong"),
  // ===== Recomendation =====
  general_assesment: yup.string().required("Tidak boleh kosong"),
  follow_up_from_bpdlh: yup.string().required("Tidak boleh kosong"),
  follow_up_from_other: yup.string().required("Tidak boleh kosong"),
  completion_time_bpdlh: yup.date().required("Tidak boleh kosong"),
  completion_time_other: yup.date().required("Tidak boleh kosong"),
});

const OnSiteForm = () => {
  const links = [
    { label: "Dana Program", path: "#" },
    { label: "On Site", path: "/on-site" },
    { label: "Detail" },
  ];

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUE,
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <FormProvider {...methods}>
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-1">
            <TitleText className="w-full">Laporan Monitoring On Site</TitleText>
            <span className="text-sm font-light">Dirubah Pada: -</span>
          </div>

          <Button
            startIcon={<BiSave size={16} />}
            onClick={() => methods.handleSubmit(onSubmit())()}
            className="bg-primary-600 text-white hover:bg-primary-700"
          >
            Simpan Laporan
          </Button>
        </div>

        <GeneralInformation />
        <ExcecutiveSummary />
        <ActivityProgram />
        <Recomendation />
        <AdditionalInformation control={methods.control} />
      </FormProvider>
    </div>
  );
};

export default OnSiteForm;
