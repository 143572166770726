import { Button, CustomSelect, Label } from "components";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { FiArrowDown, FiArrowUp, FiPlus, FiTrash } from "react-icons/fi";
import { map, update } from "lodash";
import { CheckboxForm, InputForm } from "components/HookForm";
import { CgClose } from "react-icons/cg";
import { Fragment, useEffect } from "react";
import Form from "./form";

const QuestionSection = ({ name }) => {
  const { control, watch } = useFormContext();
  const { fields, append, remove, replace, update } = useFieldArray({
    control,
    name: `${name}.questions`,
  });

  const questions = useWatch({ control, name: `${name}.questions` });

  const insertAndShift = (from, to) => {
    let cutOut = questions.splice(from, 1)[0];
    questions.splice(to, 0, cutOut);
    replace(questions);
  };

  return (
    <div className="flex flex-col gap-4">
      {map(fields, (field, key) => (
        <div
          key={field.id}
          className="flex flex-col border border-primary-200 rounded-lg gap-4 p-6"
        >
          {/* Button */}
          <div className="flex gap-4">
            <Button
              size="md"
              startIcon={<FiArrowUp />}
              disabled={key === 0}
              className={
                key === 0
                  ? "bg-[#E0E0E0] border-[#E0E0E0] text-[#292D32] hover:text-white"
                  : "bg-white border-primary-300 text-primary-800 hover:text-white"
              }
              onClick={() => insertAndShift(key, key - 1)}
            />
            <Button
              size="md"
              startIcon={<FiArrowDown />}
              disabled={key === questions.length - 1}
              className={
                key === questions.length - 1
                  ? "bg-[#E0E0E0] border-[#E0E0E0] text-[#292D32] hover:text-white"
                  : "bg-white border-primary-300 text-primary-800 hover:text-white"
              }
              onClick={() => insertAndShift(key, key + 1)}
            />
            {key > 0 && (
              <Button
                size="md"
                startIcon={<FiTrash />}
                className="bg-error-600 border-error-600"
                onClick={() => remove(key)}
              />
            )}
          </div>

          <Form
            name={`${name}.questions.${key}`}
            // updateOptions={(data) => update(key, data)}
            // questionType={watch(`${name}.questions.${key}.questionType`)?.value}
          />

          {watch(`${name}.questions.${key}.questionType`) && (
            <OptionSection
              name={`${name}.questions.${key}.options`}
              subName={`${name}.questions.${key}.subQuestions`}
              type={watch(`${name}.questions.${key}.questionType`)?.value}
            />
          )}
        </div>
      ))}
      <div className="flex w-full gap-4 justify-end">
        <Button
          type="button"
          size="md"
          startIcon={<AiOutlinePlus />}
          className="w-fit"
          onClick={() =>
            append({
              questionName: null,
              questionType: null,
              options: [
                {
                  name: "",
                  is_correct: false,
                },
              ],
            })
          }
        >
          Tambah Pertanyaan
        </Button>
      </div>
    </div>
  );
};

const OptionSection = ({ name, subName, type }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const {
    fields: subField,
    append: subAppend,
    remove: subRemove,
  } = useFieldArray({
    control,
    name: subName,
  });

  return (
    <div className="h-full space-y-4">
      {type === "multiple" &&
        map(fields, (field, key) => (
          <div key={field.id} className="flex items-center gap-4">
            <div className="flex flex-col w-full gap-2">
              <div className="flex flex-col gap-1 w-full">
                <Label>Nama Jawaban</Label>
                <InputForm name={`${name}.${key}.name`} className="w-full" />
              </div>
              <CheckboxForm
                name={`${name}.${key}.is_correct`}
                className="form-control accent-primary-700"
                label="Tandai Sebagai Jawaban yang benar"
                id={`${name}.${key}.is_correct`}
              />
            </div>

            <button
              className="bg-error-600 p-2.5 rounded"
              onClick={() => remove(key)}
            >
              <CgClose className="text-white" />
            </button>
          </div>
        ))}

      {map(subField, (field, key) => (
        <SubOptionSection
          name={`${subName}.${key}`}
          id={key}
          key={field.id}
          removeSub={subRemove}
        />
      ))}

      <hr />

      <div className="flex w-full justify-end gap-4">
        {type === "multiple" && (
          <Button
            type="button"
            size="md"
            startIcon={<AiOutlinePlus />}
            className="w-fit bg-white text-slate-700 hover:text-white"
            onClick={() => {
              append({
                name: "",
                is_correct: false,
              });
            }}
          >
            Tambah Opsi
          </Button>
        )}

        <Button
          type="button"
          size="md"
          startIcon={<AiOutlinePlus />}
          className="w-fit bg-primary-700 border-primary-700 text-white hover:text-white hover:bg-primary-800 hover:border-primary-700"
          onClick={() => {
            subAppend({
              questionName: null,
              questionType: null,
              answerType: null,
              options: [
                {
                  name: "",
                  is_correct: false,
                },
              ],
            });
          }}
        >
          Tambah Sub Pertanyaan
        </Button>
      </div>
    </div>
  );
};

const SubOptionSection = ({ name, removeSub, id }) => {
  const { control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${name}.options`,
  });

  return (
    <div className="flex flex-col p-5 gap-4 border border-primary-300 rounded-xl">
      <div className="flex items-center justify-between">
        <span className="text-primary-700 font-medium">Sub Pertanyaan</span>
        <button
          onClick={() => removeSub(id)}
          className="text-red-600 font-extrabold"
        >
          -
        </button>
      </div>
      <Form name={name} />

      {watch(`${name}.questionType`)?.value === "multiple" && (
        <Fragment>
          {map(fields, (field, key) => (
            <div className="flex items-center gap-4" key={field.id}>
              <div className="flex flex-col w-full gap-2">
                <div className="flex flex-col gap-1 w-full">
                  <Label>Nama Jawaban</Label>
                  <InputForm
                    name={`${name}.options.${key}.name`}
                    className="w-full"
                  />
                </div>
                <CheckboxForm
                  name={`${name}.options.${key}.is_correct`}
                  className="form-control accent-primary-700"
                  label="Tandai Sebagai Jawaban yang benar"
                />
              </div>

              <button
                className="bg-error-600 p-2.5 rounded"
                onClick={() => remove(key)}
              >
                <CgClose className="text-white" />
              </button>
            </div>
          ))}
          <hr />

          <div className="flex w-full justify-end gap-4">
            <Button
              type="button"
              size="md"
              startIcon={<AiOutlinePlus />}
              className="w-fit bg-white text-slate-700 hover:text-white"
              onClick={() => {
                append({
                  name: "",
                  is_correct: false,
                });
              }}
            >
              Tambah Opsi
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default QuestionSection;
