import config from "app/config";
import axiosInstance from "app/interceptors";

export const getGroupList = (params, signal) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/group`,
    { params, signal }
  );
};

export const getGroupDetail = async (id, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/group/${id}`,
    { signal }
  );
  return data;
};

export const getGroupMonitoringPlan = async (id, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/group/${id}/calendar`,
    { signal }
  );
  return data;
};

export const getGroupEvaluation = async (params, signal) => {
  const { id, ...restParams } = params;
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/group/${id}/evaluation`,
    { ...restParams, signal }
  );
  return data;
};

export const getGroupMember = async (params, signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/group/member/${params?.id}`,
    { params: params?.payload, signal }
  );
  return data;
};
