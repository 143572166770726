import { useDeptorMonitoringPlan } from "hooks/useDeptor";
import React from "react";

const MonitoringPlan = ({ id }) => {
  const { data } = useDeptorMonitoringPlan(["deptor-monitoring-plan", id]);

  const months = [
    "Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
  ];

  const renderTable = (startIndex, endIndex) => (
    <div className="flex-1 border border-gray-200 rounded-xl overflow-hidden">
      <table className="table table-compact w-full">
        <thead>
          <tr>
            {months.slice(startIndex, endIndex).map((month) => (
              <th key={month} className="w-1/6 text-center bg-primary-50 text-gray-600">
                {month}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {months.slice(startIndex, endIndex).map((month, index) => (
              <td key={month} className="text-center">
                {data?.result && data?.result[startIndex + index] ? data?.result[startIndex + index].total_plan : '-'}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="flex-1 space-y-4">
      {renderTable(0, 6)}
      {renderTable(6, 12)}
    </div>
  );
};

export default MonitoringPlan;
