import { Table, TitleText } from "components";
import { useEvaluatePlanningQuery } from "hooks/useEvaluationPlan";
import { useState } from "react";
import { useQueryClient } from "react-query";

const RealizationTable = ({ filteredValue }) => {
  const queryClient = useQueryClient();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data, isLoading, refetch } = useEvaluatePlanningQuery([
    "evaluate-planning-realization",
    {
      page,
      limit,
      status: "approved",
      search: filteredValue?.nap,
      filter_province: filteredValue?.province,
      filter_city: filteredValue?.city,
      filter_district: filteredValue?.district,
      filter_group: filteredValue?.groupId,
    },
  ]);

  const paginator = data?.data?.result?.meta;

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          // parseInt(paginator?.page) * parseInt(paginator?.limit) -
          // parseInt(paginator?.limit) +
          index + 1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "deptor_name",
      title: "Nama Debitur",
      dataIndex: "group_profile_member",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value?.name ?? "-"}
        </div>
      ),
    },
    {
      id: "monev_result",
      title: "Hasil Monev",
      dataIndex: "monev_result",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value, data) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {data?.evaluation?.[0]?.result ?? "-"}
        </div>
      ),
    },
    // {
    //   id: "actions",
    //   title: "Action",
    //   dataIndex: "actions",
    //   // width: 160,
    //   className: "overflow-hidden text-ellipsis",
    //   sortable: true,
    //   // columnClassName: "text-center",
    //   fixed: "right",
    //   render: (value, data, index) => (
    //     <div className="flex ">
    //       <BsEye
    //         className="text-gray-600 cursor-pointer flex-1"
    //         size={20}
    //         onClick={() => {}}
    //       />
    //     </div>
    //   ),
    // },
  ];

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeRowsPerPage = (limit) => {
    setLimit(limit);
  };

  return (
    <div className="flex w-full p-0">
      <div className="flex w-full card bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="flex justify-between card-header p-3 gap-2">
          <TitleText className="flex-1">
            Daftar Debitur Proses Realisasi
          </TitleText>
        </div>
        <div className="card-body p-3">
          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4"
            columns={tableColumns}
            dataSource={data?.data?.result?.data}
            isLoading={isLoading}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            pagination={{
              page: paginator?.current_page,
              limit: paginator?.per_page,
              itemCount: paginator?.total,
              previous_pages: paginator?.previous_page_url,
              next_pages: paginator?.next_page_url,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RealizationTable;
