import { Breadcrumbs, CustomSelect, SearchInput, TitleText } from "components";
import { useWorkingAreaQuery } from "hooks/useMasterData";
import { debounce, map } from "lodash";
import { useCallback, useState } from "react";

import KorwilTable from "./components/Table/korwil-table";
import PICTable from "./components/Table/pic-table";
import PLTable from "./components/Table/pl-table";
import { useUserProfile } from "hooks/useUser";

const Assignment = () => {
  const [active, setActive] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [filter_region, setRegion] = useState("");

  const { data: workingAreaOpt } = useWorkingAreaQuery(["working-area-opt"]);
  const { data: profile } = useUserProfile(["user-profile"]);

  const dataWilayah =
    workingAreaOpt?.data?.result.length > 0
      ? workingAreaOpt?.data?.result.map(({ id, name }) => {
          return {
            label: name,
            value: id,
          };
        })
      : [];

  const tabs = [
    {
      label: "PIC",
    },
    {
      label: "KPL",
    },
    {
      label: "PL",
    },
  ];

  const links = [
    {
      label: "Penugasan",
    },
  ];

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const onChangeArea = (event) => {
    setRegion(event.label);
  };

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <div className="flex items-center justify-between">
        <div className="flex items-center my-3 justify-between flex-1">
          <TitleText className="flex-1">Penugasan</TitleText>
        </div>
        <div className="flex items-center space-x-4 flex-1">
          <div className="flex-1">
            <SearchInput placeholder="Pencarian" onChange={onSearch} />
          </div>
          <div className="flex-1">
            <CustomSelect
              name="search-area"
              placeholder="Pilih Area"
              onChange={onChangeArea}
              optionsData={dataWilayah}
            />
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="tabs">
        {map(tabs.filter(tab => {
          // Show tabs based on the user's role
          if (profile?.role === "KDIV") return tab.label === "PIC" || tab.label === "KPL" || tab.label === "PL"; 
          if (profile?.role === "PIC") return tab.label === "KPL" || tab.label === "PL"; 
          if (profile?.role === "KPL") return tab.label === "PL"; 
          if (profile?.role === "PL") return tab.label === ""; 
          return true; // Default case, show all tabs
        }), (tab, key) => (
          <button
            onClick={() => setActive(key)}
            className={`flex-1 tab tab-bordered font-semibold  hover:text-primary-700 ${
              key === active
                ? "text-primary-700 border-b-[3px] border-b-primary-700"
                : "border-b text-gray-500"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {active === 0 && (
        <PICTable keyword={keyword} filter_region={filter_region} />
      )}
      {active === 1 && (
        <KorwilTable keyword={keyword} filter_region={filter_region} />
      )}
      {active === 2 && (
        <PLTable keyword={keyword} filter_region={filter_region} />
      )}
    </div>
  );
};

export default Assignment;
