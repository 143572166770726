import { CollapsingCard, Label } from "components";
import { InputForm } from "components/HookForm";
import React from "react";

const Recomendation = () => {
  return (
    <CollapsingCard title="Rekomendasi">
      <div className="flex flex-col gap-6">
        <div className="p-6 border border-primary-600 rounded">
          <div className="flex flex-col gap-1">
            <Label>Penilaian Umum</Label>
            <InputForm
              type="textarea"
              className="w-full"
              name="general_assesment"
            />
          </div>
        </div>

        <div className="flex flex-col gap-4 p-6 border border-primary-600 rounded">
          <div className="flex flex-col gap-1">
            <Label>Tindak Lanjut Yang Diperlukan Dari BPDLH:</Label>
            <InputForm
              type="textarea"
              className="w-full"
              name="follow_up_from_bpdlh"
            />
          </div>

          <div className="flex flex-col gap-1">
            <Label>Waktu Penyelesaian</Label>
            <InputForm type="date" name="completion_time_bpdlh" className="w-full" />
          </div>
        </div>

        <div className="flex flex-col gap-4 p-6 border border-primary-600 rounded">
          <div className="flex flex-col gap-1">
            <Label>Tindak Lanjut Yang Diperlukan Dari Pihak Lain:</Label>
            <InputForm
              type="textarea"
              className="w-full"
              name="follow_up_from_other"
            />
          </div>

          <div className="flex flex-col gap-1">
            <Label>Waktu Penyelesaian</Label>
            <InputForm type="date" name="completion_time_other" className="w-full" />
          </div>
        </div>
      </div>
    </CollapsingCard>
  );
};

export default Recomendation;
