import React from "react";
import { BiSearch } from "react-icons/bi";

const SearchInput = ({ placeholder = "Cari", onChange }) => {
  return (
    <div className="rounded-lg bg-white flex flex-1 items-center  border-gray-200 px-4 space-x-3 border-solid border-[1px]">
      <BiSearch className="text-gray-400" size={20} />
      <input
        className="bg-white text-slate-700 w-full !ml-0 text-sm p-2 outline-none border-none"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchInput;
