import { useQuery } from "react-query";
import { getGroupDetail, getGroupEvaluation, getGroupList, getGroupMember, getGroupMonitoringPlan } from "services/group";

export const useGroupQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return getGroupList({ ...params }, signal);
    },
    ...options,
  });
};

export const useGroupDetailQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return getGroupDetail(id, signal);
    },
    ...options,
  });
};

export const useGroupMonitoringPlan = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return getGroupMonitoringPlan(id, signal);
    },
    ...options,
  });
};

export const useGroupEvaluation = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return getGroupEvaluation(id, signal);
    },
    ...options,
  });
};

export const useGroupMemberQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return getGroupMember({ ...params }, signal);
    },
    ...options,
  });
};