import { CustomSelect, SearchInput, Spinner, Table, Button } from "components";
import { CustomInput } from "components/HookForm/CustomDatepickerForm";
import useBulkApproval from "hooks/useBulkApproval";
import useGetListTable from "hooks/useGetListTable";
import { useProgramDropdownList } from "hooks/useMasterData";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Datepicker from "react-datepicker";
import { HiOutlineEye } from "react-icons/hi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { PROGRAM, PROGRESS_REPORT } from "services";
import { FormProvider } from "react-hook-form";

const TableReview = ({ status }) => {
  const navigate = useNavigate();

  const [active, setActive] = useState(0);
  const [selectedDate, setSelectedDate] = useState({
    tanggal_update: null,
    tanggal_verifikasi: null,
  });
  const [params, setParams] = useState({});

  const {
    isLoading,
    data: kemajuanData,
    refetch,
    page,
    limit,
    keyword,
    setOrderBy,
    setOrderDirection,
    setKeyword,
    setPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useGetListTable(
    `progress-report-${status}`,
    PROGRESS_REPORT.getLaporanKemajuan,
    {
      ...params,
      status,
    }
  );

  const {
    renderModal,
    approveModal,
    setApproveModal,
    declineModal,
    setDeclineModal,
    type,
    setType,
    id,
    setId,
    methods,
  } = useBulkApproval(PROGRESS_REPORT.putBulkStatusLaporan, refetch);

  const { programList } = useProgramDropdownList();
  useEffect(() => {
    setPage(1);
    setParams({});
    setSelectedDate({
      ...selectedDate,
      tanggal_update: "",
      tanggal_verifikasi: "",
    });
  }, [status, params.programIds]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(kemajuanData?.paginator?.page) *
            parseInt(kemajuanData?.paginator?.limit) -
          parseInt(kemajuanData?.paginator?.limit) +
          index +
          1;

        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "submission_date",
      title: "Tanggal Update",
      dataIndex: "submission_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy(data);
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : ""}
        </div>
      ),
    },
    {
      id: "cfp.program_name",
      title: "Proyek",
      dataIndex: "cfp.program_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy("program_name");
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "submitter",
      title: "Pelapor",
      dataIndex: "submitter",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy(data);
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "avg_achievement",
      title: "AVG Capaian",
      dataIndex: "avg_achievement",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy(data);
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "submission_date",
      title: "Tanggal Verifikasi",
      dataIndex: "submission_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy(data);
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : ""}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Action",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: false,
      sorter: (data, direction) => {
        setOrderBy(data);
        setOrderDirection(direction);
      },
      // columnClassName: "text-center",
      fixed: "right",
      hasCheckbox: status === 0 ? true : false,
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => navigate(`/program-progress/detail/${data.id}`)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <div className="">
        <div className="flex items-center space-x-4 flex-1">
          <div className="flex-1">
            <SearchInput placeholder="Pencarian" onChange={onSearch} />
          </div>
          <div className="flex-1">
            <Datepicker
              dateFormat="yyyy-MM-dd"
              customInput={
                <CustomInput
                  placeholderText="Tanggal Update"
                  onClear={() => {
                    setSelectedDate({ ...selectedDate, tanggal_update: "" });
                    setParams({
                      ...params,
                      updatedDate: "",
                    });
                  }}
                />
              }
              onChange={(date) => {
                setSelectedDate({ ...selectedDate, tanggal_update: date });
                setParams({
                  ...params,
                  updatedDate: moment(date).format("YYYY-MM-DD"),
                });
              }}
              selected={selectedDate.tanggal_update}
            />
            {/* <CustomDatepickerForm */}
          </div>
          <div className="flex-1">
            <Datepicker
              dateFormat="yyyy-MM-dd"
              customInput={
                <CustomInput
                  placeholderText="Tanggal Verifikasi"
                  onClear={() => {
                    setSelectedDate({
                      ...selectedDate,
                      tanggal_verifikasi: "",
                    });
                    setParams({
                      ...params,
                      submissionDate: "",
                    });
                  }}
                />
              }
              onChange={(date) => {
                setSelectedDate({ ...selectedDate, tanggal_verifikasi: date });
                setParams({
                  ...params,
                  submissionDate: moment(date).format("YYYY-MM-DD"),
                });
              }}
              selected={selectedDate.tanggal_verifikasi}
            />
          </div>
          <div className="flex-1">
            <CustomSelect
              placeholder="Proyek"
              optionsData={programList}
              isMulti
              onChange={(data) => {
                const programIds = data.map((e) => e.value).toString();
                setParams({ ...params, programIds });
              }}
            />
          </div>
        </div>
      </div>
      
      {status === 0 && (

        <div className="flex items-center space-x-4 flex-1">
          <div className="flex flex-1 gap-2">
            <Button
              onClick={() => {
                setDeclineModal(true);
                setType(2);
              }}
              className="w-1/2 border-white bg-white text-red-500 hover:bg-white hover:border-white hover:text-red-700"
              disabled={id.length === 0}
            >
              Tolak
            </Button>
            <Button
              onClick={() => {
                setApproveModal(true);
                setType(1);
              }}
              className="w-1/2 text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
              disabled={id.length === 0}
            >
              Disetujui
            </Button>
          </div>
        </div>
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-3">
            <div className="space-y-4">
              <Table
                bordered
                stripped
                className="mb-4"
                columns={tableColumns}
                dataSource={kemajuanData?.result}
                onChangePage={onChangePage}
                initialRowPerPage={limit}
                initialPage={page}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onIdSelected={(data) => setId(data)}
                pagination={kemajuanData?.paginator}
              />
            </div>
          </div>
        </div>
      )}
      <FormProvider {...methods}>{renderModal()}</FormProvider>
    </div>
  );
};

export default TableReview;
