import OnSite from "pages/Program/OnSite";
import OnSiteForm from "pages/Program/OnSite/Form";

const onSiteRoutes = [
  {
    routeType: "public",
    path: "/on-site",
    element: OnSite,
    exact: true,
    type: "canView",
    key: "on-site",
  },
  // {
  //   routeType: "public",
  //   path: "/on-site/add/:id",
  //   element: InvestigateForm,
  //   exact: true,
  //   type: "canView",
  //   key: "on-site-add",
  // },
  {
    routeType: "public",
    path: "/on-site/detail/:id",
    element: OnSiteForm,
    exact: true,
    type: "canView",
    key: "on-site-detail",
  },
];

export default onSiteRoutes;
