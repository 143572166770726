import { ConfirmationModal } from "components/Modal";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiPencil, BiTrash } from "react-icons/bi";
import { HiOutlineEye } from "react-icons/hi";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { WP } from "services";

const { Breadcrumbs, TitleText, Button, Table } = require("components");

const EvaluatePaperWork = () => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [deletePaperModal, setDeletePaperModal] = useState(false);

  const {
    isLoading,
    data: workingPaperData,
    refetch,
  } = useQuery({
    queryKey: [
      "working-paper",
      {
        page,
        limit,
      },
    ],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await WP.getWorkingPaperList({ ...params }, signal);
      return response.data;
    },
  });

  const removeWorkingPaper = useMutation((payload) =>
    WP.deleteWorkingPaper(payload)
  );

  const links = [
    {
      label: "Evaluasi",
      path: "#",
    },
    {
      label: "Kertas Kerja",
    },
  ];

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(workingPaperData?.paginator?.page) *
            parseInt(workingPaperData?.paginator?.limit) -
          parseInt(workingPaperData?.paginator?.limit) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "program_name",
      title: "Jenis Layanan",
      dataIndex: "program_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "created_at",
      title: "Tgl. Dibuat",
      dataIndex: "created_at",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD MMMM YYYY h:mm:ss a") : "-"}
        </div>
      ),
    },
    {
      id: "updated_at",
      title: "Tgl. Diubah",
      dataIndex: "updated_at",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD MMMM YYYY h:mm:ss a") : "-"}
        </div>
      ),
    },
    {
      id: "status",
      title: "Status",
      dataIndex: "status",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <>
          {value?.toLowerCase() === "inactive" ? (
            <span className="py-1 px-2 text-xs text-red-700 font-medium bg-red-50 border border-red-700 rounded-lg">
              {value}
            </span>
          ) : value?.toLowerCase() === "active" ? (
            <span className="py-1 px-2 text-xs text-primary-700 font-medium bg-primary-50 border border-primary-700 rounded-lg">
              {value}
            </span>
          ) : (
            <span className="py-1 px-2 text-xs font-medium bg-slate-50 border border-slate-700 rounded-lg">
              {value}
            </span>
          )}
        </>
      ),
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() =>
              navigate(`/evaluate-paper-work/detail`, { state: data })
            }
          />
          <BiTrash
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => triggerDelete(data)}
          />
          <BiPencil
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() =>
              navigate(`/evaluate-paper-work/edit`, { state: data })
            }
          />
        </div>
      ),
    },
  ];

  const triggerDelete = (data) => {
    setSelected(data);
    setDeletePaperModal(true);
  };

  const onDeleteData = () => {
    removeWorkingPaper.mutate(selected?.id, {
      onSuccess: () => {
        refetch();
        setDeletePaperModal(false);
        setSelected(null);
        enqueueSnackbar("Berhasil menghapus data", { variant: "success" });
      },
      onError: (error, variables) => {
        console.log(error, variables);
      },
    });
  };

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeRowsPerPage = (limit) => {
    setLimit(limit);
  };

  const renderModal = () => {
    return (
      <ConfirmationModal
        open={deletePaperModal}
        onClose={setDeletePaperModal}
        onSubmit={onDeleteData}
      />
    );
  };

  return (
    <>
      {renderModal()}
      <div className="flex flex-col gap-4">
        <Breadcrumbs items={links} />
        <div className="md:flex block items-center my-3 justify-between">
          <TitleText className="flex-1">Kertas Kerja</TitleText>
          <Button
            className="bg-primary-800 border-primary-800 text-white hover:bg-primary-800/90 hover:border-primary-800/90"
            size="md"
            startIcon={<AiOutlinePlus />}
            onClick={() => navigate("/evaluate-paper-work/add")}
          >
            Tambah Kertas Kerja
          </Button>
        </div>

        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-3">
            <div className="space-y-4">
              <Table
                bordered
                stripped
                layout="fixed"
                className="mb-4"
                columns={tableColumns}
                dataSource={workingPaperData?.result}
                isLoading={isLoading}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={workingPaperData?.paginator}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EvaluatePaperWork;
