import { Button, Table, TitleText } from "components";
import {
  useEvaluatePlanningQuery,
  useEvaluationPlanningRemove,
} from "hooks/useEvaluationPlan";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import { useQueryClient } from "react-query";

const RegisteredPlanningTable = ({ date }) => {
  const queryClient = useQueryClient();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [dataChecked, setDataChecked] = useState([]);

  const {
    data: registeredPlan,
    isLoading,
    refetch,
  } = useEvaluatePlanningQuery(
    [
      "evaluate-planning-registered",
      {
        page,
        limit,
        is_plan: true,
        plan_date: date,
      },
    ],
    { enabled: !!date }
  );

  const paginator = registeredPlan?.data?.result?.meta;

  const remove = useEvaluationPlanningRemove();

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(paginator?.current_page) * parseInt(paginator?.per_page) -
          parseInt(paginator?.per_page) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "nap",
      title: "NAP",
      dataIndex: "request",
      width: 540,
      className: "w-1/2 overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value.nap ?? "-"}
        </div>
      ),
    },
    {
      id: "deptor_name",
      title: "Nama Debitur",
      dataIndex: "group_profile_member",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value.name ?? "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Action",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <AiOutlineMinusCircle
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => handleOnlyOne(data)}
          />
        </div>
      ),
    },
    {
      id: "actions",
      title: () => (
        <input
          type="checkbox"
          className="cursor-pointer accent-primary-700 w-4 h-4"
          onChange={handleCheckAll}
          checked={dataChecked.length === registeredPlan?.data?.result?.data.length}
        />
      ),
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <input
            type="checkbox"
            className="cursor-pointer accent-primary-700 w-4 h-4"
            checked={dataChecked.includes(data.id)}
            onChange={() => handleCheck(data)}
          />
        </div>
      ),
    },
  ];

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeRowsPerPage = (limit) => {
    setLimit(limit);
  };

  const handleCheck = (data) => {
    const id = data.id; // Get the id directly

    setDataChecked((prevState) => {
      if (prevState.includes(id)) {
        // Check if the id is already in the array
        return prevState.filter((x) => x !== id); // Remove the id
      } else {
        return [...prevState, id]; // Add the id
      }
    });
  };

  const handleSelected = () => {
    const payload = {
      debtor_id: dataChecked,
    };

    remove.mutate(payload, {
      onSuccess: () => {
        enqueueSnackbar({
          message: `Berhasil!`,
          variant: "success",
        });
        refetch();
        setDataChecked([]);
        queryClient.invalidateQueries({
          queryKey: "evaluate-planning-unregistered",
        });
        queryClient.invalidateQueries({
          queryKey: "list-timeline",
        });
      },
      onError: (error) => {
        enqueueSnackbar({
          message: error,
          variant: "error",
        });
      },
    });
  };

  const handleOnlyOne = (data) => {
    const debtorId = [];

    debtorId.push(data.id);
    const payload = {
      debtor_id: debtorId,
    };

    remove.mutate(payload, {
      onSuccess: () => {
        enqueueSnackbar({
          message: `Berhasil!`,
          variant: "success",
        });
        refetch();
        setDataChecked([]);
        queryClient.invalidateQueries({
          queryKey: "evaluate-planning-unregistered",
          refetchInactive: true,
        });
        queryClient.invalidateQueries({
          queryKey: "list-timeline",
        });
      },
      onError: (error) => {
        enqueueSnackbar({
          message: error,
          variant: "error",
        });
      },
    });
  };

  const handleCheckAll = (event) => {
    if (event.target.checked) {
      // If the header checkbox is checked, add all ids to dataChecked
      const allIds = registeredPlan?.data?.result?.data.map((item) => item.id);
      setDataChecked(allIds);
    } else {
      // If the header checkbox is unchecked, clear dataChecked
      setDataChecked([]);
    }
  };

  return (
    <div className="flex flex-1 card bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
      <div className="flex justify-between card-header p-3 gap-2">
        <TitleText className="flex-1">
          Daftar Debitur terdaftar dalam perencanaan
        </TitleText>
        <Button
          className="text-white bg-[#BC3A12] border-[#BC3A12] hover:bg-[#BC3A12]/80 hover:border-[#BC3A12]/80"
          startIcon={<FiTrash />}
          disabled={dataChecked?.length <= 0}
          onClick={handleSelected}
        >
          Hapus terpilih
        </Button>
      </div>
      <div className="card-body p-3">
        <Table
          bordered
          stripped
          layout="fixed"
          className="mb-4"
          columns={tableColumns}
          dataSource={registeredPlan?.data?.result?.data}
          isLoading={isLoading}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          pagination={{
            page: paginator?.current_page,
            limit: paginator?.per_page,
            itemCount: paginator?.total,
            previous_pages: paginator?.previous_page_url,
            next_pages: paginator?.next_page_url,
          }}
        />
      </div>
    </div>
  );
};

export default RegisteredPlanningTable;
