import { SearchInput, Table } from "components";
import useGetListTable from "hooks/useGetListTable";
import { useState } from "react";
import moment from "moment";
import { HiOutlineEye } from "react-icons/hi";
import { useNavigate } from "react-router";
import { FiAlertTriangle } from "react-icons/fi";
import { INVESTIGATE } from "services";

const InvestigateTable = ({ status }) => {
  const navigate = useNavigate();
  const [params, setParams] = useState({});

  const {
    data,
    isLoading,
    setOrderBy,
    setOrderDirection,
    setKeyword,
    setPage,
    onChangePage,
    onChangeRowsPerPage,
    onSearch,
  } = useGetListTable("investigation-list", INVESTIGATE.getInvestigate, {
    ...params,
    status,
  });

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, _, index) => {
        const no =
          parseInt(data?.paginator?.page) * parseInt(data?.paginator?.limit) -
          parseInt(data?.paginator?.limit) +
          index +
          1;

        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "name",
      title: "Nama Kegiatan",
      dataIndex: "name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy(data);
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "date",
      title: "Tgl. Pelaksanaan",
      dataIndex: "date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy(data);
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD MMMM YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "cost",
      title: "Biaya",
      dataIndex: "cost",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy(data);
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "activityPlan",
      title: "Rencana Kegiatan",
      dataIndex: "activityPlan",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy(data);
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "program",
      title: "Program",
      dataIndex: "program",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy(data);
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Action",
      hasCheckbox: status === 0 ? true : false,
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => navigate(`/on-desk/detail/${data.id}`)}
          />
          {status === 0 && (
            <FiAlertTriangle
              className="text-yellow-400 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate(`/on-desk/add/${data.id}`)}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex items-center space-x-4 flex-1">
          <div className="flex-1">
            <SearchInput placeholder="Pencarian" onChange={onSearch} />
          </div>
        </div>
      </div>
      <div className="flex w-full p-0">
        <div className="card w-full bg-white shadow-sm rounded-xl border border-gray-200">
          <div className="card-body p-3">
            <div className="space-y-4">
              <Table
                bordered
                stripped
                layout="fixed"
                className="mb-3"
                columns={tableColumns}
                dataSource={data?.result}
                pagination={data?.paginator}
                isLoading={isLoading}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestigateTable;
