import { CollapsingCard, CustomSelect, Label, Table } from "components";
import { InputForm } from "components/HookForm";
import { SideModal } from "components/Modal";
import { Fragment, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { HiOutlineEye } from "react-icons/hi";

const ActivityProgram = () => {
  const [isOpenActivity, setIsOpenActivity] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);

  const activityData = [
    {
      output: "Pengoperasian mesin peraut lidi",
      initial_hue: 0,
      target: "Target 2 unit",
      status: "Sesuai",
    },
  ];

  const activityMethods = useForm();

  const tableActivityColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "w-fit",
      render: (value, data, index) => {
        const no = index + 1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[140px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "output",
      title: "Output",
      dataIndex: "output",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "initial_hue",
      title: "Rona Awal",
      dataIndex: "initial_hue",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "target",
      title: "Capaian",
      dataIndex: "target",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "status",
      title: "Sesuai/Tidak",
      dataIndex: "status",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Action",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      fixed: "right",
      render: (value, data, index) => (
        <div className="flex items-center w-fit gap-4">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => {
              onOpenDetailActivity(data);
            }}
          />

          <BiPencil
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            // onClick={() => onOpenEdit(data)}
          />
        </div>
      ),
    },
  ];

  const tableIssueColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "w-fit",
      render: (value, data, index) => {
        const no = index + 1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[140px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "activity_date",
      title: "Isu",
      dataIndex: "activity_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "monitoring_implementer",
      title: "Sub Isu",
      dataIndex: "monitoring_implementer",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "activity_purpose",
      title: "Sesuai/Tidak",
      dataIndex: "activity_purpose",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Action",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      fixed: "right",
      render: (value, data, index) => (
        <div className="flex items-center w-fit gap-4">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            // onClick={() => {
            //   onOpenDetailActivity(data);
            // }}
          />

          <BiPencil
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            // onClick={() => onOpenEdit(data)}
          />
        </div>
      ),
    },
  ];

  const onOpenDetailActivity = (data) => {
    activityMethods.reset({
      status: { label: data?.status, value: data?.status },
    });

    setIsOpenActivity(true);
    setDataSelected(data);
  };

  const renderModal = () => {
    return (
      <Fragment>
        <SideModal
          open={isOpenActivity}
          onClose={() => {
            setIsOpenActivity(false);
            setDataSelected(null);
          }}
          title="Kegiatan Program/Kegiatan"
          // onSubmit={methodsPenugasan.handleSubmit(onSubmit)}
          width="w-1/3"
          position="center-right"
          modalClassName="w-1/3 h-screen rounded-none"
          btnLabelSubmit="Simpan Perubahan"
          btnLabelCancel="Batal"
        >
          <FormProvider {...activityMethods}>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <Label>Output</Label>
                <span>{dataSelected?.output ?? "-"}</span>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex flex-col gap-1">
                  <Label>Rona Awal</Label>
                  <span>{dataSelected?.initial_hue ?? 0}</span>
                </div>

                <div className="flex flex-col gap-1">
                  <Label>Capaian</Label>
                  <span>{dataSelected?.target ?? "-"}</span>
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <Label>Sesuai/Tidak</Label>
                <CustomSelect
                  name="status"
                  className="w-full"
                  optionsData={[
                    { label: "Sesuai", value: "Sesuai" },
                    { label: "Tidak", value: "Tidak" },
                  ]}
                  placeholder="Pilih salah satu"
                  isClearable
                />
              </div>

              <div className="flex flex-col gap-1">
                <Label>Keterangan</Label>
                <InputForm
                  name="description"
                  type="textarea"
                  placeholder="Keterangan"
                  className="w-full"
                />
              </div>
            </div>
          </FormProvider>
        </SideModal>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {renderModal()}
      <CollapsingCard title="Kegiatan Program/Kegiatan">
        <div className="flex flex-col gap-6">
          <div className="space-y-4 p-6 border border-primary-600 rounded">
            <Label>Kegiatan Program/Kegiatan</Label>

            <Table
              columns={tableActivityColumns}
              dataSource={activityData}
              hidePagination
            />
          </div>

          <div className="space-y-4 p-6 border border-primary-600 rounded">
            <Label>Isu Safeguards</Label>

            <Table columns={tableIssueColumns} dataSource={[]} hidePagination />
          </div>
        </div>
      </CollapsingCard>
    </Fragment>
  );
};

export default ActivityProgram;
