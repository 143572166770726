import { useProgramQuery } from "hooks/useProgram";
import { useEffect, useState } from "react";
import AsyncSelectForm from ".";

const SelectProgramOptions = ({ isOpen, ...props }) => {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setPage(1);
      setSearch("");
    }
  }, [isOpen]);

  const { data: programData, isFetching } = useProgramQuery([
    "program-options",
    { page, limit, search, ...props.filter },
  ]);

  return (
    <AsyncSelectForm
      {...props}
      data={programData?.result}
      totalPages={programData?.result?.paginator?.itemCount}
      getOptionLabel={(opt) => opt?.program_name}
      getOptionValue={(opt) => opt?.program_id}
      isFetching={isFetching}
      onChangePage={setPage}
      onChangeSearch={setSearch}
      menuPortalTarget={document.body}
    />
  );
};

export default SelectProgramOptions;
