import { Breadcrumbs, Table, TitleText } from "components";
import React, { useState } from "react";
import { BiPencil } from "react-icons/bi";
import { HiOutlineEye } from "react-icons/hi";
import { useNavigate } from "react-router";

const OnSite = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");

  const navigate = useNavigate();

  const links = [{ label: "Dana Program", path: "#" }, { label: "On Site" }];

  const data = [
    {
      id: "1238789173",
      activity_date: "11/11/2024",
      monitoring_implementer: "Reyhan Fikran",
      location: "Bandung",
      activity_purpose: "Monitoring",
    },
  ];

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "w-fit",
      render: (value, data, index) => {
        const no =
          parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[140px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "activity_date",
      title: "Tanggal Kegiatan",
      dataIndex: "activity_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (column, methods) => {
        setSortBy(column);
        setSortType(methods);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "monitoring_implementer",
      title: "Pelaksana Monitoring",
      dataIndex: "monitoring_implementer",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "location",
      title: "Lokasi",
      dataIndex: "location",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "activity_purpose",
      title: "Tujuan Kegiatan",
      dataIndex: "activity_purpose",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Action",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      fixed: "right",
      render: (value, data, index) => (
        <div className="flex items-center w-fit gap-4">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => {
              onOpenDetail(data);
            }}
          />

          <BiPencil
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            // onClick={() => onOpenEdit(data)}
          />
        </div>
      ),
    },
  ];

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeRowsPerPage = (limit) => {
    setLimit(limit);
  };

  const onOpenDetail = (data) => {
    navigate(`/on-site/detail/${data.id}`);
  };

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <TitleText className="w-full">Laporan Monitoring On Site</TitleText>

      {/* Table */}
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={data}
              // isLoading={isLoading}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              // pagination={{
              //   page: data?.paginator?.page ?? 1,
              //   limit: data?.paginator?.limit ?? 10,
              //   itemCount: data?.paginator?.itemCount ?? 0,
              //   previous_pages: data?.paginator?.hasPrevPage,
              //   next_pages: data?.paginator?.hasNextPage,
              // }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnSite;
