import classNames from "classnames";
import {
  useListTimelineQuery,
  useMonitoringTotalQuery,
} from "hooks/useEvaluationPlan";
import moment from "moment";
import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const localizer = momentLocalizer(moment);

const PlanningCalendar = ({ onEventClick, isSelected }) => {
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [month, setMonth] = useState(new Date().getMonth());

  const { data: listTimeline } = useListTimelineQuery([
    "list-timeline",
    {
      month: month + 1,
      year: new Date().getFullYear(),
    },
  ]);

  const { data: monitoringTotal } = useMonitoringTotalQuery([
    "monitoring-total",
    {
      month: month + 1,
      year: new Date().getFullYear(),
    },
  ]);

  const handleSelectEvent = (event) => {
    setSelectedEventId(event.id);
    onEventClick(event);
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      let mDate = toolbar.date;
      let newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
      setMonth(newDate.getMonth());
      toolbar.onNavigate("prev", newDate);
    };

    const goToNext = () => {
      let mDate = toolbar.date;
      let newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
      setMonth(newDate.getMonth());
      toolbar.onNavigate("next", newDate);
    };

    return (
      <div className="flex w-full mb-3">
        <div className="flex justify-between items-center w-full bg-[#F9FAFB] border border-[#D0D5DD] py-3 px-4 rounded-lg">
          <BsChevronLeft onClick={goToBack} className="cursor-pointer" />
          <span>{toolbar.label}</span>
          <BsChevronRight onClick={goToNext} className="cursor-pointer" />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full bg-primary-50 py-5 px-3 gap-4">
      <div className="flex justify-between">
        <span className="text-lg font-semibold">
          Tanggal perencanaan monitoring
        </span>
        <span className="text-base font-medium">
          Total Monitoring: {monitoringTotal?.data?.result?.total ?? "-"}
        </span>
      </div>

      <Calendar
        localizer={localizer}
        events={listTimeline?.data?.result}
        startAccessor="start"
        endAccessor="end"
        className="custom-rbc"
        style={{ height: 700, width: "100%" }}
        showAllEvents
        view="month"
        onSelectEvent={handleSelectEvent} // Update to use functional state
        components={{
          event: (props) => (
            <CustomEvent
              {...props}
              isSelected={props.event.id === selectedEventId && isSelected}
            />
          ),
          toolbar: CustomToolbar,
        }}
      />

      <div className="flex justify-center items-center gap-2">
        <div className="flex items-center gap-1 rounded-[2px]">
          <div className="w-[15px] h-[15px] bg-[#F6FB7A]"></div>
          <span>Perencanaan</span>
        </div>
        <div className="flex items-center gap-1 rounded-[2px]">
          <div className="w-[15px] h-[15px] bg-[#A5CA4A]"></div>
          <span>Realisasi</span>
        </div>
      </div>
    </div>
  );
};

const CustomEvent = (props) => {
  return (
    <div
      className={classNames(
        "flex w-full text-slate-800 justify-center overflow-hidden p-1 rounded-lg",
        { "border-2 border-green-500": props.isSelected }
      )}
    >
      <div className="flex-1 bg-[#F6FB7A] py-4 text-center">
        {props.event.total_plan ?? "0"}
      </div>
      <div className="flex-1 bg-[#A5CA4A] py-4 text-center">
        {props.event.total_realization ?? "0"}
      </div>
    </div>
  );
};

export default PlanningCalendar;
