import { useCityQuery } from "hooks/useMasterData";
import { useState } from "react";
import AsyncSelectForm from ".";

const SelectCityOptions = ({ ...props }) => {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [search, setSearch] = useState("");

  const { data: cityData, isFetching } = useCityQuery([
    "city-options",
    { page, limit, search, province_id: props.provinceId, ...props.filter },
  ],{ enabled: !!props.provinceId});

  return (
    <AsyncSelectForm
      {...props}
      data={cityData?.data?.result?.data}
      totalPages={cityData?.data?.result?.meta?.total}
      getOptionLabel={(opt) => opt?.city_name}
      getOptionValue={(opt) => opt?.city_id}
      isFetching={isFetching}
      onChangePage={setPage}
      onChangeSearch={setSearch}
      menuPortalTarget={document.body}
    />
  );
};

export default SelectCityOptions;
