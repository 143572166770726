import config from "app/config";
import axiosInstance from "app/interceptors";

export const getListMasterData = (pathName) => {
  return axiosInstance.get(
    `${config.BASE_URL_BPLDH}/master/v1/${pathName}/list`
  );
};

export const getWorkingArea = () => {
  const data = axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/master/working-area`
  );

  return data;
};

export const getProvince = (params, signal) => {
  const data  = axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/master/province`,
    { params, signal }
  );

  return data;
};

export const getCity = (params, signal) => {
  const data  = axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/master/city`,
    { params, signal }
  );

  return data;
};

export const getDistrict = (params, signal) => {
  const data  = axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/master/district`,
    { params, signal }
  );

  return data;
};
