import { useMutation, useQuery } from "react-query";
import { EVALUATE_PLANNING } from "services";

export const useEvaluatePlanningQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return EVALUATE_PLANNING.getEvaluationPlan({ ...params }, signal);
    },
    ...options,
  });
};

export const useListTimelineQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return EVALUATE_PLANNING.getListTimeline({ ...params }, signal);
    },
    ...options,
  });
};

export const useMonitoringTotalQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return EVALUATE_PLANNING.getMonitoringTotal({ ...params }, signal);
    },
    ...options,
  });
};

export const useEvaluationPlanningAdd = () => {
  return useMutation((payload) => EVALUATE_PLANNING.addEvaluationPlan(payload));
};

export const useEvaluationPlanningRemove = () => {
  return useMutation((payload) => EVALUATE_PLANNING.removeEvaluationPlan(payload));
};
