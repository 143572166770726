import { CollapsingCard, Label } from "components";
import { InputForm } from "components/HookForm";
import React from "react";

const ExcecutiveSummary = () => {
  return (
    <CollapsingCard title="Ringkasan Eksekutif">
      <div className="p-6 border border-primary-600 rounded">
        <div className="flex flex-col gap-1">
          <Label required>
            Uraian Singkat Kemajuan Pelaksanaan Dan Temuan Masalah
          </Label>
          <InputForm
            type="textarea"
            className="w-full"
            name="brief_description"
            placeholder="Masukkan uraian singkat"
          />
        </div>
      </div>
    </CollapsingCard>
  );
};

export default ExcecutiveSummary;
