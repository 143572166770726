import { Breadcrumbs, TitleText } from "components";
import { map } from "lodash";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router";
import InvestigateTable from "./InvestigateTable";

const Investigate = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);

  const links = [
    {
      label: "Laporan",
      path: "#",
    },
    {
      label: "Investigasi",
    },
  ];

  const [tabs, setTabs] = useState([
    {
      label: "Proses",
    },
    {
      label: "Selesai",
    },
  ]);

  const data = [
    {
      activity_name: "Dana Terra",
      implementation_date: "17 Februari 2023",
      cost: 1000000,
      activity_plan: "Kegiatan A",
      program: "Penanaman Pohon",
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs items={links} />

      <div className="md:flex block items-center my-3 justify-between">
        <TitleText className={"flex-1"}>Laporan On Desk</TitleText>
      </div>

      {/* Tabs */}
      <div className="tabs">
        {map(tabs, (tab, key) => (
          <a
            onClick={() => setActive(key)}
            className={`flex-1 tab tab-bordered font-medium hover:text-primary-700 ${
              key === active
                ? "text-primary-700 bg-primary-50 border-b-2 border-b-primary-700"
                : "border-b"
            }`}
          >
            {tab.label}
          </a>
        ))}
      </div>

      <InvestigateTable status={active} />
    </Fragment>
  );
};

export default Investigate;
