import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { useNavigate } from "react-router";

import {
  Breadcrumbs,
  SearchInput,
  Table,
  TitleText
} from "components";

import SelectGroupFDBOptions from "components/HookForm/AsyncSelectForm/select-group-fdb";
import { useDeptorQuery } from "hooks/useDeptor";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";

const Deptor = () => {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState(undefined);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const links = [
    {
      label: "Daftar Debitur FDB",
    },
  ];

  const methods = useForm();

  const { isLoading, data: deptorData } = useDeptorQuery([
    "deptor-list",
    {
      page,
      limit,
      search: keyword,
      group_id: methods.watch("group-opt")?.group_id,
    },
  ]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(deptorData?.data?.meta?.current_page) *
            parseInt(deptorData?.data?.meta?.per_page) -
          parseInt(deptorData?.data?.meta?.per_page) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "nap",
      title: "NAP",
      dataIndex: "request",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value.nap : "-"}
        </div>
      ),
    },
    {
      id: "name",
      title: "Nama Penerima",
      dataIndex: "group_profile_member",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value.name : "-"}
        </div>
      ),
    },
    {
      id: "korwil",
      title: "Korwil",
      dataIndex: "korwil",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "final_assesment",
      title: "Penilaian Akhir",
      dataIndex: "final_assesment",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "submit_at",
      title: "Tgl Submit",
      dataIndex: "created_at",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value?.submit_at).format("DD MMMM YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: () => <div className="text-center">Action</div>,
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => navigate(`/deptor/detail`, { state: data })}
          />
        </div>
      ),
    },
  ];

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeRowsPerPage = (limit) => {
    setLimit(limit);
  };

  return (
    <div>
      <Breadcrumbs items={links} />

      <FormProvider {...methods}>
        <div className="md:flex block items-center my-3 justify-between">
          <TitleText className="flex-1">Daftar Debitur FDB</TitleText>
          <div className="flex items-center space-x-4 flex-1">
            <div className="flex-1">
              <SearchInput placeholder="Pencarian" onChange={onSearch} />
            </div>
            <div className="flex-1">
              <SelectGroupFDBOptions
                name="group-opt"
                placeholder="Pilih Salah satu"
                isClearable
              />
            </div>
          </div>
        </div>
      </FormProvider>

      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={deptorData?.data?.data}
              isLoading={isLoading}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={{
                page: deptorData?.data?.meta?.current_page,
                limit: deptorData?.data?.meta?.per_page,
                itemCount: deptorData?.data?.meta?.total,
                previous_pages: deptorData?.data?.meta?.previous_page_url,
                next_pages: deptorData?.data?.meta?.next_page_url,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deptor;
