import { useProvinceQuery } from "hooks/useMasterData";
import { useState } from "react";
import AsyncSelectForm from ".";

const SelectProvinceOptions = ({ ...props }) => {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [search, setSearch] = useState("");

  const { data: provinceData, isFetching } = useProvinceQuery([
    "province-options",
    { page, limit, search, ...props.filter },
  ]);

  return (
    <AsyncSelectForm
      {...props}
      data={provinceData?.data?.result?.data}
      totalPages={provinceData?.data?.result?.meta?.total}
      getOptionLabel={(opt) => opt?.prov_name}
      getOptionValue={(opt) => opt?.prov_id}
      isFetching={isFetching}
      onChangePage={setPage}
      onChangeSearch={setSearch}
      menuPortalTarget={document.body}
    />
  );
};

export default SelectProvinceOptions;
