import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ContributionChart = ({ data }) => {
  const currentContribution = parseInt(data);
  const remainingContribution = 100 - parseInt(data);
  
  const options = {
    chart: {
      backgroundColor: "#F9FAFB",
      type: "pie",
    },
    title: {
      text: "",
    },
    plotOptions: {
      pie: {
        shadow: false,
        dataLabels: {
          color: "white",
          distance: -20,
          formatter: function () {
            if (this.y !== 0) return Math.round(this.y) + "%";
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        return "<b>" + this.point.name + "</b>: " + this.y + " %";
      },
    },
    series: [
      {
        name: "Browsers",
        // data: [["Firefox",6],["MSIE",4],["Chrome",7]],
        data: [
          {
            y: currentContribution,
            color: "#00BD52",
            name: "Current Contribution"
          },
          {
            y: remainingContribution,
            color: "#dcfce7",
            name: "Total Contribution"
          },
        ],
        innerSize: "60%",
        showInLegend: true,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ContributionChart;
