import { useQuery } from "react-query";
import { USER } from "services";

export const useUserProfile = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: async ({ signal }) => {
      let response = await USER.getUserProfile(signal);
      return response.data;
    },
    ...options,
  });
};
