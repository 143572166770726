import ProgramAssignment from "pages/Program/Assignment";

const assignmentRoutes = [
  {
    routeType: "public",
    path: "/assignment-program",
    element: ProgramAssignment,
    exact: true,
    type: "canView",
    key: "program-assignment",
  },
];

export default assignmentRoutes;
