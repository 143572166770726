import approveIcon from "assets/approved.svg";
import deleteIcon from "assets/delete.svg";
import {
  Breadcrumbs,
  Button,
  CustomSelect,
  Input,
  Label,
  TitleText
} from "components";
import CustomModal from "components/Modal/CustomModal";
import { map } from "lodash";
import { useState } from "react";
import { CgClose } from "react-icons/cg";
import { useNavigate } from "react-router";
import MonitoringTable from "./MonitoringTable";

const Monitoring = () => {
  const navigate = useNavigate();

  const [active, setActive] = useState(0);

  const links = [
    {
      label: "Penugasan",
    },
  ];

  const [tabs, setTabs] = useState([
    {
      label: "Perlu Tinjauan",
    },
    {
      label: "Disetujui",
    },
    {
      label: "Ditolak",
    },
  ]);

  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isOpenDecline, setIsOpenDecline] = useState(false);

  const [isReasonChecked, setIsReasonChecked] = useState(false);
  const [search, setSearch] = useState("");

  // const onSearch = debounce(
  //   useCallback(
  //     (event) =>
  //       setKeyword(() => {
  //         if (event?.target?.value) return event.target.value;
  //         return undefined;
  //       }),
  //     []
  //   ),
  //   1000
  // );

  const renderModal = () => {
    return (
      <>
        {/* Modal Terima */}
        <CustomModal open={isOpenApprove}>
          <div className="flex-1 flex items-center justify-between">
            <img src={approveIcon} />
            <CgClose
              className="text-xl cursor-pointer"
              onClick={() => setIsOpenApprove(false)}
            />
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <span className="font-bold text-lg width-[473px]">
                Setujui Laporan?
              </span>
              <span className="font-normal text-sm text-primary-25">
                Pastikan data sudah sesuai.
              </span>
            </div>

            <div className="flex items-center gap-2 text-sm">
              <input
                id="approve"
                type="checkbox"
                className="!accent-primary-600 cursor-pointer"
                checked={isReasonChecked}
                onChange={() => setIsReasonChecked(!isReasonChecked)}
              />
              <label htmlFor="approve" className="cursor-pointer">
                Setujui Bersyarat
              </label>
            </div>

            {isReasonChecked && (
              <div className="flex flex-col gap-1">
                <Label>Alasan Disetujui</Label>
                <Input name="reason" className="form-control w-full"></Input>
              </div>
            )}

            <hr />

            <div className="flex-1 flex items-center space-x-4">
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 flex-1 border-0"
                onClick={() => setIsOpenApprove(false)}
              >
                Batal
              </Button>
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-500 hover:border-primary-500 flex-1"
                onClick={() => setIsOpenApprove(false)}
              >
                Setujui
              </Button>
            </div>
          </div>
        </CustomModal>

        {/* Modal Tolak */}
        <CustomModal open={isOpenDecline}>
          <div className="flex-1 flex items-center justify-between">
            <img src={deleteIcon} />
            <CgClose
              className="text-xl cursor-pointer"
              onClick={() => setIsOpenDecline(false)}
            />
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <span className="font-bold text-lg width-[473px]">
                Tolak Laporan?
              </span>
              <span className="font-normal text-sm text-primary-25">
                Pastikan data sudah sesuai.
              </span>
            </div>

            <div className="flex flex-col gap-1">
              <Label>Kategori Penolakan</Label>
              <CustomSelect name="kategori" optionsData={[]} value={null} />
            </div>

            <div className="flex flex-col gap-1">
              <Label>Alasan Penolakan</Label>
              <Input
                name="reason"
                placeholder="Alasan Penolakan"
                className="w-full"
              />
            </div>

            <hr />

            <div className="flex-1 flex items-center space-x-4">
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 flex-1 border-0"
                onClick={() => setIsOpenDecline(false)}
              >
                Batal
              </Button>
              <Button
                type="button"
                className="text-white border-[#BC3A12] bg-[#BC3A12] hover:bg-[#BC3A12] hover:border-[#BC3A12] flex-1"
                onClick={() => setIsOpenDecline(false)}
              >
                Tolak
              </Button>
            </div>
          </div>
        </CustomModal>
      </>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {renderModal()}
      <Breadcrumbs items={links} />

      <div className="md:flex block items-center my-3 justify-between">
        <TitleText className={"flex-1"}>Laporan Monitoring</TitleText>

        {/* <div className="flex items-center space-x-4 flex-1">
          <div className="flex-1">
            <SearchInput
              placeholder="Pencarian"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="flex-1">
            <CustomSelect value={null} placeholder="Filter" optionsData={[]} />
          </div>
        </div> */}
      </div>

      {/* Tabs */}
      <div className="tabs">
        {map(tabs, (tab, key) => (
          <button
            onClick={() => setActive(key)}
            className={`flex-1 tab tab-bordered font-medium hover:text-primary-700 ${
              key === active
                ? "text-primary-700 bg-primary-50 border-b-2 border-b-primary-700"
                : "border-b"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <MonitoringTable status={active} />
    </div>
  );
};

export default Monitoring;
