import { useGroupQuery } from "hooks/useGroup";
import { useState } from "react";
import AsyncSelectForm from ".";

const SelectGroupFDBOptions = ({ ...props }) => {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [search, setSearch] = useState("");

  const { data: groupData, isFetching } = useGroupQuery([
    "group-options",
    { page, limit, search, ...props.filter },
  ]);

  return (
    <AsyncSelectForm
      {...props}
      data={groupData?.data?.result}
      totalPages={groupData?.data?.result?.meta?.total}
      getOptionLabel={(opt) => opt?.name}
      getOptionValue={(opt) => opt?.id}
      isFetching={isFetching}
      onChangePage={setPage}
      onChangeSearch={setSearch}
      menuPortalTarget={document.body}
    />
  );
};

export default SelectGroupFDBOptions;
