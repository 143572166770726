import { useMutation, useQuery } from "react-query";
import { PROGRAM_ASSIGNMENT } from "services";

export const useProgramAssignmentQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await PROGRAM_ASSIGNMENT.getProgramAssignment({ ...params }, signal);
      return response.data;
    },
    ...options,
  });
};

export const useProgramAssignmentDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: async ({ queryKey }) => {
      const params = queryKey[1];
      let response = await PROGRAM_ASSIGNMENT.getProgramAssignmentDetail(params);
      return response.result;
    },
    ...options,
  });
};

export const useProgramAssignmentPatch = () => {
  return useMutation((payload) => PROGRAM_ASSIGNMENT.putProgramAssignment(payload));
};
