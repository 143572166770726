import React, { useMemo, useCallback, useState, useEffect } from "react";
import ReactSelect from "react-select";
import { useFormContext, Controller } from "react-hook-form";

let NEXT_PAGE = null;

const CustomSelectForm = ({ placeholder = "Cari ...", ...props }) => {
  const { name } = props;
  const { control } = useFormContext();

  const handleOnChange = (value, onChange) => {
    if (props?.onValueChanged && typeof props?.onValueChanged === "function") {
      props?.onValueChanged(value);
    }
    onChange(value);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => (
        <div className="w-full">
          <ReactSelect

            {...props}
            placeholder={placeholder}
            value={value}
            onChange={(val) => handleOnChange(val, onChange)}
            onBlur={onBlur}
            styles={{
              placeholder: (styles) => ({
                ...styles,
                color: "#98A2B3",
                fontSize: 14,
                fontWeight: 400,
              }),
              input: (styles) => ({
                ...styles,
                input: {
                  "-webkit-box-shadow": "none !important",
                },
              }),
              control: (styles) => ({
                ...styles,
                borderColor: invalid ? "#dc3545" : "#EAECF0",
                ":hover": {
                  ...styles[":hover"],
                  borderColor: invalid ? "#dc3545" : "#EAECF0",
                },

                boxShadow: "none",
                borderRadius: "6px",
              }),
              singleValue: (styles) => ({
                ...styles,
                color: "#3e4340",
              }),
              indicatorContainer: (styles, { isFocused }) => ({
                ...styles,
                color: isFocused ? "#98A2B3" : "#3e4340",
                ":hover": {
                  ...styles[":hover"],
                  color: "#3e4340",
                },
              }),
              option: (
                styles,
                { data, isSelected, isFocused, isDisabled }
              ) => ({
                ...styles,
                cursor: isDisabled ? "not-allowed" : "auto",
                backgroundColor: isDisabled
                  ? undefined
                  : isSelected
                  ? "#01A24A"
                  : isFocused
                  ? "#EEFFF4"
                  : undefined,
                color: isDisabled
                  ? "hsl(0, 0%, 80%)"
                  : isSelected
                  ? "#fff"
                  : isFocused
                  ? "#047857"
                  : data.color,
              }),
            }}
            menuPortalTarget={document.body}
          />
          {invalid && (
            <div className="text-xs text-error-600 mt-2">{error?.message}</div>
          )}
        </div>
      )}
    />
  );
};

export default React.memo(CustomSelectForm);
