import config from "app/config";
import axiosInstance from "app/interceptors";

export const getUserDetail = async (userId, signal) => {
  const { data } = await axiosInstance.get(
    `/user-management/v1/User/internal/${userId}`,
    { signal }
  );
  return data;
};

export const getUserProfile = async (signal) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/users/profile`,
    { signal }
  );
  return data;
};
