import config from "app/config";
import axiosInstance from "app/interceptors";

export const getDeptorList = (params, signal) => {
  const data = axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/debtor`,
    { params, signal }
  );

  return data;
};

export const getDeptorDetail = async (id) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/debtor/${id}/detail`
  );
  return data;
};

export const getDeptorMonitoringPlan = async (id) => {
  const data = await axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/debtor/${id}/calendar`
  );
  return data;
};

export const getDeptorMonev = async (params, signal) => {
  const { id, ...restParams } = params;
  const data = await axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/debtor/${id}/evaluation`,
    { params: restParams, signal }
  );
  return data;
};

export const createNewDeptor = (payload) => {
  return axiosInstance.post(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/debtor`,
    payload
  );
};

export const editDeptor = (params) => {
  return axiosInstance.put(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/debtor/${params?.id}`,
    params?.payload
  );
};

export const deleteDeptor = (id) => {
  return axiosInstance.delete(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/debtor/${id}`
  );
};
