import { Breadcrumbs, CollapsingCard, Table } from "components";
import { map } from "lodash";
import { useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";

import ApplicationData from "./applicationData";
import BusinessData from "./businessData";
import GroupInformation from "./groupInformation";

import { useGroupDetailQuery, useGroupMemberQuery } from "hooks/useGroup";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";
import Monev from "./Monev";
import MonitoringPlan from "./MonitoringPlan";

const GroupDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [active, setActive] = useState(0);

  const [memberPage, setMemberPage] = useState(1);
  const [memberLimit, setMemberLimit] = useState(10);

  const id = useMemo(() => location?.state?.group_id ?? null);

  const tabs = [
    "Rencana Monitoring",
    "Monev",
  ];

  const { isLoading, data: groupDetail } = useGroupDetailQuery(
    ["group-detail", id],
    { enabled: !!id }
  );

  const { isLoading: isLoadingMember, data: groupMember } = useGroupMemberQuery(["group-member", { id, page: memberPage, limit: memberLimit}], {
    enabled: !!id,
  });


  const links = [
    {
      path: "/deptor",
      label: "Daftar Debitur",
    },
    {
      label: "Detail Debitur",
    },
  ];

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 10,
      total: 50,
      previous_pages: true,
      next_pages: true,
    };
  });

  const onChangePage = (page) => {
    setMemberPage(page);
  };

  const onChangeRowsPerPage = (limit) => {
    setMemberLimit(limit);
  };

  const tableColumnsGroupMember = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(paginator?.page) * parseInt(paginator?.limit) -
          parseInt(paginator?.limit) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "nap",
      title: "NAP",
      dataIndex: "request",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value?.nap ?? "-"}
        </div>
      ),
    },
    {
      id: "receiver_name",
      title: "Nama Penerima",
      dataIndex: "group_profile_member",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value?.name ?? "-"}
        </div>
      ),
    },
    {
      id: "korwil",
      title: "Korwil",
      dataIndex: "group_profile_member",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value?.working_area_name ?? "-"}
        </div>
      ),
    },
    {
      id: "status",
      title: "Status",
      dataIndex: "status",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "submit_date",
      title: "Tgl Submit",
      dataIndex: "created_at",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD-MM-YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Action",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => navigate(`/deptor/detail`, { state: data })}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      {groupDetail?.member_application?.group_profile && (
        <CollapsingCard title="Informasi Kelompok">
          <GroupInformation
            kupsName={groupDetail?.member_application?.group_profile?.kups_name}
            schemePs={"-"}
            appCategory={"-"}
            sk={groupDetail?.member_application?.group_profile?.sk}
            permissionNumberPS={"="}
            amountOfMember={
              groupDetail?.member_application?.group_profile?.amount_of_member
            }
            address={groupDetail?.member_application?.group_profile?.address}
            long={groupDetail?.member_application?.group_profile?.long}
            lat={groupDetail?.member_application?.group_profile?.lat}
            leaderName={
              groupDetail?.member_application?.group_profile?.leader_name
            }
            leaderPhoneNumber={
              groupDetail?.member_application?.group_profile
                ?.leader_phone_number
            }
            secretaryName={
              groupDetail?.member_application?.group_profile?.secretary_name
            }
            secretaryPhoneNumber={
              groupDetail?.member_application?.group_profile
                ?.secretary_phone_number
            }
            treasurerName={
              groupDetail?.member_application?.group_profile?.treasurer_name
            }
            treasurerPhoneNumber={
              groupDetail?.member_application?.group_profile
                ?.treasurer_phone_number
            }
            companionName={
              groupDetail?.member_application?.group_profile?.companion_name
            }
            companionPhoneNumber={
              groupDetail?.member_application?.group_profile
                ?.companion_phone_number
            }
            companionStatus={
              groupDetail?.member_application?.group_profile?.companion_status
            }
            businessPartner={"-"}
            activity={"-"}
            general_description={
              groupDetail?.member_application?.group_profile?.status
            }
          />
        </CollapsingCard>
      )}
      <CollapsingCard title="Data Usaha">
        <BusinessData
          name={groupDetail?.business_type ? groupDetail?.business_type : "-"}
          duration={
            groupDetail?.business_duration &&
            groupDetail?.business_duration_unit
              ? `${groupDetail?.business_duration} ${groupDetail?.business_duration_unit}`
              : "-"
          }
          priceLastSeller={
            groupDetail?.business_economic_value
              ? groupDetail?.business_economic_value
              : "-"
          }
          marketingObjective={
            groupDetail?.marketing_objective
              ? groupDetail?.marketing_objective
              : "-"
          }
          quantityCommodity={
            groupDetail?.qty_business_commodity
              ? groupDetail?.qty_business_commodity
              : "-"
          }
          usagePlan={groupDetail?.usage_plan ? groupDetail?.usage_plan : "-"}
          businessCommodity={
            groupDetail?.business_commodity
              ? groupDetail?.business_commodity
              : "-"
          }
          productivity={
            groupDetail?.productivity ? groupDetail?.productivity : "-"
          }
          cultivated={
            groupDetail?.land_area_cultivated
              ? groupDetail?.land_area_cultivated
              : "-"
          }
          businessPartner={
            groupDetail?.business_partner ? groupDetail?.business_partner : "-"
          }
          address={
            groupDetail?.business_address ? groupDetail?.business_address : "-"
          }
          long={groupDetail?.business_lat ? groupDetail?.business_lat : "-"}
          lat={groupDetail?.business_long ? groupDetail?.business_long : "-"}
        />
      </CollapsingCard>
      <CollapsingCard title="Data Permohonan">
        <ApplicationData
          amount_of_loan={
            groupDetail?.amount_of_loan ? groupDetail?.amount_of_loan : "-"
          }
          period={
            groupDetail?.time_period && groupDetail?.time_period_unit
              ? `${groupDetail?.time_period} ${groupDetail?.time_period_unit}`
              : "-"
          }
          financing_scheme={
            groupDetail?.financing_scheme ? groupDetail?.financing_scheme : "-"
          }
        />
      </CollapsingCard>
      <CollapsingCard title="Daftar Anggota Kelompok">
        <Table
          bordered
          stripped
          layout="fixed"
          className="mb-4"
          columns={tableColumnsGroupMember}
          dataSource={groupMember?.data?.data}
          isLoading={isLoadingMember}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          pagination={{
            page: groupMember?.data?.meta?.current_page ?? 1,
            limit: groupMember?.data?.meta?.per_page ?? 10,
            itemCount: groupMember?.data?.meta?.total,
            prevPage: groupMember?.data?.meta?.previous_pages_url ?? null,
            nextPage: groupMember?.data?.meta?.next_pages_url ?? null,
          }}
        />
      </CollapsingCard>
      <div className="space-y-4">
        <div className="tabs">
          {map(tabs, (tab, key) => (
            <button
              onClick={() => setActive(key)}
              className={`flex-1 tab tab-bordered font-medium hover:text-primary-700 ${
                key === active
                  ? "text-primary-700 bg-primary-50 border-b-2 border-b-primary-700"
                  : "border-b"
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
        {active === 0 && <MonitoringPlan id={location.state.id} />}
        {active === 1 && <Monev id={location.state.id} />}
      </div>
    </div>
  );
};

export default GroupDetail;
