import { useQuery } from "react-query";
import { PROGRAM } from "services";

export const useProgramQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await PROGRAM.getProgramList({ ...params }, signal);
      return response.data;
    },
    ...options,
  });
};