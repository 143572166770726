import { Button, Label, TitleText } from "components";
import { InputForm } from "components/HookForm";
import SelectCityOptions from "components/HookForm/AsyncSelectForm/select-city";
import SelectDistrictOptions from "components/HookForm/AsyncSelectForm/select-district";
import SelectGroupFDBOptions from "components/HookForm/AsyncSelectForm/select-group-fdb";
import SelectProvinceOptions from "components/HookForm/AsyncSelectForm/select-province";
import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { BiChevronDown, BiChevronUp, BiFilterAlt } from "react-icons/bi";

const PlanningFilter = ({ isFilterActive, setIsFilterActive, date, onFilter, defaultValue, setFilteredValue }) => {
  const { watch, setValue, reset, handleSubmit } = useFormContext();

  const allValues = watch(); // Get all form field values

  // Check if any field has a value
  const hasValues = Object.values(allValues).some(value => value !== null && value !== undefined && value !== '');
  
  const handleProvinceChange = () => {
    setValue("city", null);
    setValue("district", null);
  };
  const handleCityChange = () => {
    setValue("district", null);
  };

  return (
    <Fragment>
      <div className="md:flex items-center justify-between">
        <TitleText className="flex-1">
          Detail Perencanaan: {date ?? "-"}
        </TitleText>
        {isFilterActive ? (
          <Button
            onClick={() => setIsFilterActive(false)}
            className="text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
            size="md"
            startIcon={<BiFilterAlt />}
            endIcon={<BiChevronUp size={20} />}
          >
            Sembunyikan Filter
          </Button>
        ) : (
          <Button
            onClick={() => setIsFilterActive(true)}
            className="text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
            size="md"
            startIcon={<BiFilterAlt />}
            endIcon={<BiChevronDown size={20} />}
          >
            Lihat Filter
          </Button>
        )}
      </div>

      {/* Filter */}
      {isFilterActive && (
        <div className="flex flex-col gap-4">
          <div className="flex w-full gap-4">
            <div className="flex flex-col w-1/5 gap-2">
              <Label>NAP</Label>
              <InputForm
                name="nap"
                placeholder="Masukkan NAP"
                className="w-full"
              />
            </div>
            <div className="flex flex-col w-1/5 gap-2">
              <Label>Provinsi</Label>
              <SelectProvinceOptions
                name="province"
                placeholder="Pilih salah satu"
                className="w-full"
                onValueChanged={handleProvinceChange}
                isClearable
              />
            </div>
            <div className="flex flex-col w-1/5 gap-2">
              <Label>Kabupaten/Kota</Label>
              <SelectCityOptions
                name="city"
                placeholder="Pilih salah satu"
                provinceId={watch("province")?.prov_id}
                isDisabled={!watch("province")}
                onValueChanged={handleCityChange}
                isClearable
              />
            </div>
            <div className="flex flex-col w-1/5 gap-2">
              <Label>Kecamatan</Label>
              <SelectDistrictOptions
                name="district"
                placeholder="Pilih salah satu"
                cityId={watch("city")?.city_id}
                isDisabled={!watch("city")}
                isClearable
              />
            </div>
            <div className="flex flex-col w-1/5 gap-2">
              <Label>Group</Label>
              <SelectGroupFDBOptions
                name="group"
                placeholder="Pilih salah satu"
                isClearable
              />
            </div>
          </div>
          <div className="flex w-full justify-between gap-3">
            <Button
              className="flex-1 bg-white text-gray-700 border-[#D0D5DD] hover:text-white hover:bg-red-500 hover:border-red-500"
              size="md"
              onClick={() => {
                reset(defaultValue)
                setFilteredValue({})
              }}
            >
              Hapus
            </Button>
            <Button
              className="flex-1 text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
              size="md"
              onClick={() => handleSubmit(onFilter)()}
              disabled={!hasValues}
            >
              Terapkan Filter
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default PlanningFilter;
