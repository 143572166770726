import assignmentRoutes from "./assignment";
import dashboardRoutes from "./dashboard";
import investigateRoutes from "./investigate";
import listRoutes from "./list";
import onSiteRoutes from "./on-site";
import physicalAndFinancialRoutes from "./physical-and-financial";
import progressRoutes from "./progress";

// Combine all routes into a single array
const danaProgramRoutes = [
  ...assignmentRoutes,
  ...dashboardRoutes,
  ...investigateRoutes,
  ...onSiteRoutes,
  ...listRoutes,
  ...physicalAndFinancialRoutes,
  ...progressRoutes,
];

export default danaProgramRoutes; // Export as default