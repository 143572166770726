import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { CustomSelectForm } from "..";

const AsyncSelectForm = ({
  data,
  totalPages,
  isFetching,
  onChangePage,
  onChangeSearch,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      setOptions(handleOptions());
    }
  }, [isFetching]);

  const handleOptions = () => {
    if (loadMore) {
      return options.concat(data);
    } else {
      return data;
    }
  };

  const onMenuScrollToBottom = () => {
    if (page < totalPages) {
      setLoadMore(true);
      setPage(page + 1);

      onChangePage(page + 1);
    }
  };

  // const onInputChange = (value) => {
  //   onSearch(value);
  //   options?.length > 0 && setOptions([]);
  // };

  const onSearch = debounce((value) => {
    onChangeSearch(value);
    if (open) {
      setLoadMore(false);
      setPage(1);
    }
  }, 1000);

  const onMenuOpen = () => {
    setOpen(true);
  };

  const onMenuClose = () => {
    setOpen(false);
  };

  return (
    <CustomSelectForm
      {...props}
      isLoading={isFetching}
      options={options}
      onMenuScrollToBottom={onMenuScrollToBottom}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      onSearch={onSearch}
    />
  );
};

export default AsyncSelectForm;
